import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { changeUserPassword } from '../redux/actions/userActions';

// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from '@mui/material/TextField';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class ChangePassword extends Component {
  state = {
    open: false,
    errors: {},
    oldPassword:'',
    newPassword:'',
    confirmNewPassword:'',
    showPassword: false,
  };

  handleOpen = () => {
    this.props.handler()
  };
  handleClose = () => {
    this.props.handler()
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    let passwordData = {
    oldPassword:this.state.oldPassword,
    newPassword:this.state.newPassword,
    confirmNewPassword:this.state.confirmNewPassword,
    }
    this.props.changeUserPassword(passwordData);
    this.setState({ open: false });
  };

  handleShowPassword = (event) => {
    this.setState({ showPassword: event.target.checked });
  };


  render() {
    const { classes, UI: { loading, errors } } = this.props;

    return (
      <Fragment>
           
        
              <Card hidden={!this.props.open} onClose={this.handleClose} elevation={0}>
                <Button tip="Close" onClick={this.handleClose}>
                <ArrowBackIcon color="info" />
                  </Button>
                  <DialogTitle>Change Password</DialogTitle>
                  <DialogContent>
                    <p>Please enter your current password and provide the password you would like to change it to.</p>
                

                    {/* <form> */}
                      <TextField
                      id="oldPassword"
                      name="oldPassword"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.oldPassword}
                      label="Old Password"
                      variant="outlined"
                      placeholder="Old Password"
                      error={errors.oldPassword ? true : false}
                      helperText={errors.oldPassword}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                    <TextField
                      id="newPassword"
                      name="newPassword"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.newPassword}
                      label="New Password"
                      variant="outlined"
                      placeholder="New Password"
                      error={errors.newPassword ? true : false}
                      helperText={errors.newPassword}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                    <TextField
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.confirmNewPassword}
                      label="Confirm New Password"
                      variant="outlined"
                      placeholder="Confirm New Password"
                      error={errors.confirmNewPassword ? true : false}
                      helperText={errors.confirmNewPassword}
                      // className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                    
                    <Button type="button" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>

                    <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showPassword}
                  onChange={this.handleShowPassword}
                />
              }
              label="Show Passwords"
            />
                    {/* </form> */}
                  </DialogContent>
              </Card>
      </Fragment>
      );
  }
}

ChangePassword.propTypes = {
  changeUserPassword: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { changeUserPassword, clearErrors }
)(withStyles(styles)(ChangePassword));