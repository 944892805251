import PropTypes from 'prop-types';
import { Component } from 'react';
// MUI

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
    ...theme.spreadThis,
    errorContainer: {
      display: 'flex',
      flexDirection:'column',
      alignItems:"center",
      background:"#f4f6f6",
      borderRadius:15,
      padding: "20px 30px"
    },
    hightlight:{
        fontWeight:"bold",
        fontSize:16,
        color:"#3a4387"
    },
     disclaimer:{
        fontWeight:"bold",
        fontSize:14,
        color:"#938d94",
        maxWidth:500,
    },
    buttonsContainer:{
        display:"flex",
        gap:15
    }
})

export class Error extends Component {
  render() {
      const {classes} = this.props;

      const reloadPage = () => {
      
        window.location.reload()
      };

    return (
      <div className={classes.errorContainer}>
          {/* <h1>Welcome</h1> */}
          <h1>Something went wrong...</h1>
          <p>Please <span className={classes.hightlight}>Refresh</span> the page.</p>
          <div className={classes.buttonsContainer}>
              {/* <Link to="/">
                  <Button color="secondary" variant="contained">
                      Go To Home Page
                  </Button>
                  </Link> */}
                  <Button onClick={() => reloadPage()} color="secondary" variant="contained">Refresh</Button>
                  </div>
      </div>
    )
  }
}

Error.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(styles)(Error);