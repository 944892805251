import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import NoImg from '../images/no-img.png';
import { mediaURLBuilder } from '../util/Global';

// Redux Stuff
import { connect } from 'react-redux';
// MUI Stuff
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import { logoutUser } from '../redux/actions/userActions';
// Icons
import StarIcon from '@mui/icons-material/Star';

const styles = (theme) => ({
  ...theme.spreadThis,
  userToolbarContainer:{
marginRight:12,
    border:"2px #f4f6f6 solid",
    borderRadius:"15px",
    background:"#f4f6f6",
    width:"100%"
  },
  imageWrapper: {
    position: 'relative',
    width:"100%",
    height:"100%",
    lineHeight:"0px",
    display:"flex",
    flexDirection:"row",
    alignItems: "center",

  },
  profileImage: {
    width: 35,
    height: 35,
    objectFit: 'cover',
    maxWidth: '100%',
    borderRadius: '50%',
    marginRight:21,
    marginLeft:20,
  }, 
  UserToolbarInfo:{
    // width:'100%',
    display:'flex',
    alignItems: 'center',
    gap: '20px',
    
  },
  displayName: {
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    width: 100,
    margin:"10px 0px",
    borderRadius:"500px"
  },
  handle: {
    height: 8,
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: 80,
    margin:"10px 0px",
    borderRadius:"500px"
  }
})

export class UserToolbarMenu extends Component {
  state = {
    anchorEl: null
}

handleClick = event => this.setState({ anchorEl: event.currentTarget })
handleClose = () => this.setState({ anchorEl: null })
handleLogout = () => {
  this.setState({ anchorEl: null })
  this.props.logoutUser( this.props.history );
};

  render() {
    const { classes, user: { credentials: { username, displayName, imageUrl,karma}, loading, authenticated }} = this.props;
    
    const { anchorEl } = this.state
        return (
          
            <div className={classes.userToolbarContainer}>
              {(authenticated && !loading && imageUrl !== undefined) ? (
               
              <Button color="secondary" className="profileButton" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}  style={{width:'100%', justifyContent:'left', transitionDelay: `150ms`}}>
              
                
                <div className={classes.navbar}>
                
              <div className={classes.imageWrapper}>
                  <img src={mediaURLBuilder(imageUrl)} alt="profile" className={classes.profileImage} />
                  <div className={classes.UserToolbarInfo}>
                  <div>
                <Typography varient="body1" style={{ color:"#1c1d1d", fontSize: 16, fontWeight: 'semibold', textTransform: 'none', textAlign:'left' }}>{displayName}</Typography>
              <Typography varient="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'medium', margin:0,textTransform: 'none', textAlign:'left' }}>@{username}</Typography>
              <div style={{display:'flex', alignItems:'center'}}>
                <StarIcon  style={{ fontSize: 12, color:"#FDCC0D", marginRight:"4px" }}/>
                <Typography color="info" varient="body1" style={{ color:"#1c1d1d", fontSize: 12, fontWeight: 600 }}>{karma}</Typography>
                </div>
              </div>
              {/* <MoreIcon color="info"/> */}
              </div>
              </div>
              </div>
              
              
              </Button>
              ) : (
                <div style={{width:'100%', justifyContent:'left'}}>
              
                
                <div className={classes.navbar}>
                
              <div className={classes.imageWrapper}>
                  <img src={NoImg} alt="profile" className={classes.profileImage} />
                  <div className={classes.UserToolbarInfo}>
                  <div>
                <div className={classes.displayName} />
                <div className={classes.handle} />
              </div>
              
              </div>
              </div>
              </div>
              
              
              </div>
              )
              }
                <Menu
                    className={classes.navbar}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                  
                    {/* <MenuItem onClick={this.handleClose}>        
                    <Button color="secondary" component={Link} to="/profile">Profile</Button>
                    </MenuItem> */}
                    <MenuItem onClick={this.handleClose}>        
                    <Button color="secondary" onClick={this.handleLogout}>Log out @{username}</Button>
                    </MenuItem>
                </Menu>
            </div>
          
        );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = { logoutUser };

UserToolbarMenu.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(UserToolbarMenu));