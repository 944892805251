export const isEmpty = (string) => {
    if(string.trim() === '') return true;
    else return false;
  };
  
export const isCountLimit = (string,limit) => {
    if((string.trim().length) >= limit) return true;
    else return false;
  };
  
export const isEmail = (email) => {
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email.match(emailRegEx)) return true;
    else return false;
  };

export const containsSpecialChars = (string) => {
  var format = /[!@#$%^&*()_+\-=\[\]{} ;':"\\|,.<>\/?]+/;

if(format.test(string)){
  return true;
} else {
  return false;
}
}

const underAgeValidate = (birthday) => {
	var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
	var myAge = ~~((Date.now(currentDate) - birthday) / (31557600000));
	if(myAge < 18) {
     	    return true;
        }else{
	    return false;
	}
} 
  
export const validateSignupData = (data) => {
    let errors = {}; 
    if (isEmpty(data.email)){
      errors.email = 'Must not be empty'
    } else if (!isEmail(data.email)){
      errors.email = 'Must be a valid email address'
    }
  
    if (isEmpty(data.password)){
      errors.password = 'Must not be empty'
    } else if (!isCountLimit(data.password,4)){
      errors.password = 'Must be at least 4 characters'
    }
  
    if (data.password !== data.confirmPassword){
      errors.confirmPassword = 'Passwords must match'
    }
  
    if (isEmpty(data.username)){
      errors.username = 'Must not be empty'
    } else if (!isCountLimit(data.username,2)){
      errors.username = 'Must be at least 2 characters'
    } else if (containsSpecialChars(data.username)){
      errors.username = 'Cannot contain special characters or spaces'
    }
  
    if (isEmpty(data.displayName)){
      errors.displayName = 'Must not be empty'
    } else if (!isCountLimit(data.displayName,2)){
      errors.displayName = 'Must be at least 2 characters'
    }

    if (data.DOB === null){
      errors.DOB = 'Must not be empty'
    } else if (underAgeValidate(data.DOB)){
      errors.DOB = 'Must be at least 18 years old.'
    }
  
    return {
      errors,
      valid: Object.keys(errors).length === 0 ? true : false
    };
  };
  
  
  export const  validateLoginData = (data) => {
  
    let errors = {};
  
  if (isEmpty(data.email)) errors.email = 'Must not be empty';
  if (isEmpty(data.password)) errors.password = 'Must not be empty';
  
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
  
  };

  export const  validateChangeEmail = (email) => {
  
    let errors = {};
  
  if (isEmpty(email)) errors.email = 'Must not be empty';
  if (!isEmail(email)) errors.email = 'Not a valid email address';

  
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
  
  };

  export const  validateChangeUsername = (usernameData) => {
  
    let errors = {};
  
  if (isEmpty(usernameData.newUsername)) errors.newUsername = 'Must not be empty';
  if (usernameData.newUsername === usernameData.oldUsername) errors.newUsername = 'Must not be the same as previous username';

  
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
  
  };

  export const  validateChangePassword = (passwordData) => {
  
    let errors = {};
  
  if (passwordData.newPassword !== passwordData.confirmNewPassword) errors.confirmNewPassword = 'Passwords must match';

  
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
  
  };

  export const  validateResetPasswordData = (email) => {
  
    let errors = {};
  
  if (isEmpty(email)) errors.email = 'Must not be empty';
  
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
  
  };