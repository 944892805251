import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { changeUserEmail } from '../redux/actions/userActions';
import { isEmpty } from '../util/validation';

// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class ChangeEmail extends Component {
  state = {
    open: false,
    currentEmail:'',
    newEmail:''
  };

  handleOpen = () => {
    this.props.handler()
  };
  handleClose = () => {
    this.props.handler()
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    this.props.changeUserEmail(this.state.newEmail);
  };


  render() {
   
    const { classes, currentEmail ,UI: { loading, errors } } = this.props;

    return (
      <Fragment>
           
        
              <Card hidden={!this.props.open} onClose={this.handleClose} elevation={0}>
                <Button tip="Close" onClick={this.handleClose}>
                  <ArrowBackIcon color="info" />
                  </Button>
                  <DialogTitle>Change Email</DialogTitle>
                  <DialogContent>
                    <p>Your current email is {currentEmail}. What would you like to change it to?</p>
                

                    {/* <form> */}
                      <TextField
                      id="newEmail"
                      name="newEmail"
                      type="text"
                      value={this.state.newEmail}
                      label="New Email Address"
                      variant="outlined"
                      placeholder="New Email Address"
                      error={errors.email ? true : false}
                      helperText={errors.email}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                    
                    <Button variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading || isEmpty(this.state.newEmail)}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>
                    {/* </form> */}
                  </DialogContent>
              </Card>
      </Fragment>
      );
  }
}

ChangeEmail.propTypes = {
  changeUserEmail: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { changeUserEmail, clearErrors }
)(withStyles(styles)(ChangeEmail));