import PropTypes from 'prop-types';
import { Component } from 'react';
import MediaQuery from 'react-responsive';
import { mobileMediaQuery } from '../util/Global';

// Redux Stuff
import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { sendVerificationLink } from '../redux/actions/userActions';

// My Components
import ChangeEmail from '../components/ChangeEmail';
import ChangePassword from '../components/ChangePassword';
import ChangeUsername from '../components/ChangeUsername';
import CreateStripeSession from '../components/CreateStripeSession';
import DeleteAccount from '../components/DeleteAccount';
import ManageStripeDetails from '../components/ManageStripeDetails';
import MobileNavBar from '../components/MobileNavBar';
import NavMenu from '../components/NavMenu';

// MUI Stuff
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import withStyles from '@mui/styles/withStyles';

// Icons
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import SendIcon from '@mui/icons-material/Send';


const styles = (theme) => ({
  ...theme.spreadThis
})

export class manage extends Component {

  state = {
    currentTab: "1",
    changeEmailOpen:false,
    changeUsernameOpen:false,
    changePasswordOpen: false
  }

 

  handleVerifyEmail = () => {
    this.props.sendVerificationLink()
  };

 handleOpenChangeEmail = () => {
   this.props.clearErrors()
    this.setState({changeEmailOpen:!this.state.changeEmailOpen})
  }

  handleOpenChangeUsername = () => {
    this.props.clearErrors()
    this.setState({changeUsernameOpen:!this.state.changeUsernameOpen})
  }

  handleOpenChangePassword = () => {
    this.props.clearErrors()
    this.setState({changePasswordOpen:!this.state.changePasswordOpen})
  }



  render() {
    const { classes, UI: { message }, user: { credentials: { username, displayName, userId, stripeCustomerId, subscriptionTier }, currentUser, loading, authenticated } } = this.props;


    const handleChange = (event, newValue) => {
      this.setState({ currentTab: newValue })
    };

    

    let manageSubscription =

      <div>
        <h2>Manage Subscription</h2>
        {subscriptionTier < 2 &&
        <p>Please wait up to 5 minutes to see changes. Do not fill out the purchase form more than once as it will charge your card.</p>
        }
        {!loading && currentUser !== null ? (
          <List>
            {subscriptionTier < 2 &&
            <CreateStripeSession username={username} email={currentUser.email} subscriptionTier={subscriptionTier} stripeCustomerId={stripeCustomerId} />
            }
            {stripeCustomerId !== null &&
              <ManageStripeDetails stripeCustomerId={stripeCustomerId} />}
          </List>
        ) : (<div className="center" key={0}><CircularProgress /></div>)}
      </div>



    let accountDetails =

      <div>
        <h2>Manage Account Details</h2>
        {!loading && currentUser !== null ? (
          <List>

            { !this.state.changeEmailOpen && !this.state.changeUsernameOpen && !this.state.changePasswordOpen ? 
            (
              <div>
            {!currentUser.emailVerified ? (
              <ListItemButton
                onClick={this.handleVerifyEmail}
                disabled={message !== null}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>

                <ListItemText
                  primary="Send Email Verification"
                  secondary="You may need to check your SPAM folder"
                />
                <ChevronRightIcon />

              </ListItemButton>
            ) : (null)}

          
            <ListItemButton
                onClick={this.handleOpenChangeEmail}
                >
                  <ListItemIcon>
                   <EmailIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Change Email Address"
                    secondary={currentUser.email + " " + (currentUser.emailVerified ? "(Verified)" : "(Not Verified)") }
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>


                <ListItemButton
                onClick={this.handleOpenChangeUsername}
                >
                  <ListItemIcon>
                   <AlternateEmailIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Change Username"
                    secondary={username}
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>
        


                <ListItemButton
                onClick={this.handleOpenChangePassword}
                >
                  <ListItemIcon>
                   <PasswordIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Change Password"
                    secondary="••••••••••"
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>

                <DeleteAccount username={username} />

                </div>
            ) : (null)
            }
            

            <ChangeEmail currentEmail={currentUser.email} emailVerified={currentUser.emailVerified} open={this.state.changeEmailOpen} handler={this.handleOpenChangeEmail}/>

            <ChangeUsername currentUsername={username} open={this.state.changeUsernameOpen} handler={this.handleOpenChangeUsername}/>

            <ChangePassword open={this.state.changePasswordOpen} handler={this.handleOpenChangePassword} />

            


          </List>
        ) : (<div className="center" key={0}><CircularProgress /></div>)}
      </div>


    return (
      <div>
        {authenticated ? (
          <div className={classes.mainPageContainer}>
            <Grid container justifyContent="left" spacing={0} className={classes.mainFeedGridContainer}>
              <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
                <div className={classes.navMenuContainer}>
                  <NavMenu />
                </div>
              </Grid>
              <Grid item sm={8} xs={12} className={classes.mainFeedItemContainer}>
                <Snackbar open={message !== null} autoHideDuration={200} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                  <Alert severity="success" sx={{ width: '100%' }}>
                    {message}
                  </Alert>
                </Snackbar>

                <MediaQuery query={mobileMediaQuery}>
                  <MobileNavBar />
                </MediaQuery>

                <Box>
                  <TabContext value={this.state.currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Account Details" value="1" />
                        <Tab label="Subscription" value="2" />
                        {/* <Tab label="Item Three" value="3" /> */}
                      </TabList>
                    </Box>
                    <TabPanel value="1">{accountDetails}</TabPanel>
                    <TabPanel value="2">{manageSubscription}</TabPanel>
                  </TabContext>

                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (null)}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user
});

const mapActionsToProps = { sendVerificationLink, clearErrors };

manage.propTypes = {
  sendVerificationLink: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(manage));
