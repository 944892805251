import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import MyButton from '../util/MyButton';

// MUI Stuff
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { connect } from 'react-redux';
import { deleteTag } from '../redux/actions/dataActions';

const styles = {
  deleteButton: {
    // position: 'absolute',
    // left: '90%',
    // top: '10%'
  }
};

class DeleteTag extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  deleteTag = () => {
    this.props.deleteTag(this.props.tagId);
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MyButton
          tip="Delete Tag"
          onClick={this.handleOpen}
          btnClassName={classes.deleteButton}
        >
          <DeleteOutline color="primary"/>
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to delete {this.props.tagName} ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteTag} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteTag.propTypes = {
  deleteTag: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  tagId: PropTypes.string.isRequired
};

export default connect(
  null,
  { deleteTag }
)(withStyles(styles)(DeleteTag));