import withStyles from '@mui/styles/withStyles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { mediaURLBuilder, mobileMediaQuery } from '../util/Global';


// MUI Stuff
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import CalendarToday from '@mui/icons-material/CalendarToday';
import LinkIcon from '@mui/icons-material/Link';
import LocationOn from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';

import FacebookIcon from '../images/social_icons/facebook_icon.png';
import InstagramIcon from '../images/social_icons/instagram_icon.png';
import TiktokIcon from '../images/social_icons/tiktok_icon.png';
import TwitterIcon from '../images/social_icons/twitter_icon.png';

const styles = (theme) => ({
  ...theme.spreadThis,
  profile:{
    background:"transparent",
    margin: "50px 0px 0px 0px",
    padding:"0px 10px",
    alignItems: "center",
    display: "flex",
    justifyContent:"center",
    flexDirection: "column",
  },
  profileHeaderContainer:{
    display:"flex",
    flexDirection:"row",
    gap:15,
    alignItems:"center",
    justifyContent:"center",
      [`@media ${mobileMediaQuery}`]: { 
        flexDirection:"column",
        alignItems:"center",
        textAlign:"center", 
      },
  },
  profileHeaderDetailsContainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"left",
    gap:4,
  },
  profileIconInfoContainer:{
    display:"flex",
    gap:3,
    alignItems:"center",
    [`@media ${mobileMediaQuery}`]: { 
      justifyContent:"center",
    },
  },
  profileDetails:{
    margin: "35px 0px 20px 0px",
    textAlign: "center",
    display: "flex",
    maxWidth:"600px",
    flexDirection: "column",
    justifyContent:"center",
    alignItems: "center",
    gap: "15px",
    "& span, svg": {
      verticalAlign: "middle",
    },
    "& a": {
      color: "#1c1d1d",
      fontSize:14,
      textDecoration:"none",
      fontWeight:"bold",
      textTransform: "none"
    },
  },
  profileDetailBubbleFull:{
    width:"fit-content",
    padding: "10px 20px",
    background:"#fff",
    borderRadius: "15px",
    border:"2px #f4f6f6 solid",
    "& p":{
      fontSize:"15px",
      
    }
  },
  profileDetailBubbleFullButton:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap: 5,
    width:"100%",
    padding: "10px 20px",
    background:"#fff",
    borderRadius: "15px",
    border:"2px #f4f6f6 solid",
    "& p":{
      fontSize:"15px",
      fontWeight:"bold",
    },
    
  },
  profileDetailBubbleIcon:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap: 5,
    padding: "10px 20px",
    background:"#fff",
    borderRadius: "15px",
    border:"2px #f4f6f6 solid",
    "& p":{
      fontSize:"15px",
      fontWeight:"bold",
    }
  },
  profileDetalSidebySide:{
    display:"flex",
    flexWrap:"wrap",
    width:"100%",
    gap:15,
    justifyContent: "space-evenly"
  },
  socialLinksContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent: "center",
    gap:10,
  },
  socialLink:{
    '& img':{
      height:35
    }
  }
})


const StaticProfile = (props) => {

 const {classes, profile: {username, displayName, createdAt, imageUrl, bio, location, karma, weblinkURL, twitterHandle, tiktokHandle, facebookHandle, instagramHandle}} = props;
  
  
    return (

      <Box m={0} pt={0}>
      <div></div>
  
    
    <div className={classes.profile}>
      <div className={classes.profileHeaderContainer}>
    <div className={classes.profileImageWrapper}>
        <img src={mediaURLBuilder(imageUrl)} alt="profile" className={classes.profileImage} />
      </div>

      <div className={classes.profileHeaderDetailsContainer}>
      {displayName && <Typography color="info" variant="h4" style={{ fontWeight: 600 }}>{displayName}</Typography>}

      <Typography color="secondary" variant="body1"  style={{ fontWeight: 600 }}>
        @{username}
      </Typography>

      
        <div className={classes.profileIconInfoContainer}>
        <Fragment>
        <StarIcon  className={classes.profileIcon} style={{ color:"#FDCC0D", height:"20px" }}/>{'  '}<Typography variant="body2" style={{ fontWeight: 600, color:"#938d94" }}>{karma} Karma</Typography>
          
        </Fragment>
        </div>
      

      </div>
      </div>
      <div className={classes.profileDetails}>
     
      {bio && 
      <div className={classes.profileDetailBubbleFull}>
      <Typography variant="body2">{bio}</Typography>
      </div>
      }


        <div className={classes.profileDetalSidebySide}>
      {location && (
        <div className={classes.profileDetailBubbleIcon}>
        <Fragment>
          <LocationOn className={classes.profileIcon} color="secondary"/><Typography variant="body2" style={{ fontWeight: 500 }}>{location}</Typography>
        </Fragment>
        </div>
      )}
      {createdAt && (
        <div className={classes.profileDetailBubbleIcon}>
        <Fragment>
          <CalendarToday className={classes.profileIcon} color="secondary"/>{'  '}<Typography variant="body2" style={{ fontWeight: 500 }}>Joined {dayjs(createdAt).format('MMM YYYY')}</Typography>
          
        </Fragment>
        </div>
      )}
</div>
     

      {weblinkURL && (
        <Button className={classes.profileDetailBubbleFullButton} target="_blank" href={weblinkURL}>
        <Fragment>
        <LinkIcon  className={classes.profileIcon} style={{ color:"#938d94" }}/>{'  '}{weblinkURL}
        </Fragment>
        </Button>
      )}
      

      <div className={classes.socialLinksContainer}>
        {twitterHandle && (
          <Button className={classes.socialLink} target="_blank" href={`https://twitter.com/${twitterHandle}`}>
            <img src={TwitterIcon}/>
          </Button>
        )}

    {tiktokHandle && (  
          <Button className={classes.socialLink} target="_blank" href={`https://tiktok.com/@${tiktokHandle}`}>
            <img src={TiktokIcon}/>
          </Button>
        )}

{facebookHandle && (  
          <Button className={classes.socialLink} target="_blank" href={`https://facebook.com/${facebookHandle}`}>
            <img src={FacebookIcon}/>
          </Button>
        )}

        {instagramHandle && (  
          <Button className={classes.socialLink} target="_blank" href={`https://instagram.com/${instagramHandle}`}>
            <img src={InstagramIcon}/>
          </Button>
        )}
        
        </div>

      </div>
    </div>
  
  </Box>
    
      );
  }


StaticProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(StaticProfile);


