import ArticleIcon from '@mui/icons-material/Article';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import PhotoIcon from '@mui/icons-material/Photo';
import TextIcon from '@mui/icons-material/ShortText';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PollIcon from '@mui/icons-material/Poll';
import { BsChatDots, BsChatDotsFill, BsGear, BsGearFill, BsHouse, BsHouseFill, BsMegaphone, BsMegaphoneFill, BsPencil, BsPencilFill, BsPerson, BsPersonFill, BsShieldFill, BsShieldShaded } from "react-icons/bs";

export const minDate = new Date('2022-01-15')

export const home_types_array = ["photo","text","video"]; //"article",
export const news_types_array = ["announce","feedback","poll"];

export const desktopMediaQuery = "(min-width: 1200px)";
export const mobileMediaQuery = "(max-width: 1200px)";

 export const types_icon ={
    "photo":PhotoIcon,
    "video":VideoIcon,
    "article": ArticleIcon,
    "text": TextIcon,
    "announce":AnnouncementIcon,
    "poll":PollIcon,
    "feedback":LiveHelpIcon
}

export const icon_set = {
    "homeFilled": BsHouseFill,
    "homeOutlined": BsHouse,
    "newsFilled": BsMegaphoneFill,
    "newsOutlined": BsMegaphone,
    "chatFilled": BsChatDotsFill,
    "chatOutlined": BsChatDots,
    "profileFilled": BsPersonFill,
    "profileOutlined": BsPerson,
    "manageFilled": BsGearFill,
    "manageOutlined": BsGear,
    "adminFilled": BsShieldFill,
    "adminOutlined": BsShieldShaded,
    "createFilled": BsPencilFill,
    "createOutlined": BsPencil,
    
}

export const isEmpty = (string) => {
    if (!string) return true;
    if(string.trim() === '') return true;
    else return false;
  };

export const mediaURLBuilder = (fileName) => {
    return `https://firebasestorage.googleapis.com/v0/b/we-go-along-club-production.appspot.com/o/${fileName}?alt=media`
}