import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';

// Redux 
import { connect } from "react-redux";
import { fetchUser } from './redux/actions/userActions'

// My Components
import AuthRoute from './util/AuthRoute';
import UnauthRoute from './util/UnauthRoute';

// Pages
import welcome from './pages/welcome';
import home from './pages/home';
import news from './pages/news';
import chat from './pages/chat';
import manage from './pages/manage';
import admin from './pages/admin';
import login from './pages/login';
import join from './pages/join';
import user from './pages/user';
import profile from './pages/profile';
import forgot_password from './pages/forgot_password';
import terms from './pages/terms';
import privacy from './pages/privacy';
import NotFound from './components/NotFound';

// MUI
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';

// Utils
import themeObject from './util/theme';

const theme = createTheme(adaptV4Theme(themeObject));


class App extends Component {
    componentWillMount() {
    this.props.fetchUser();
    
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path ="/welcome" component={welcome}/>
              <UnauthRoute exact path ="/" component={home}/>
              <UnauthRoute exact path ="/news" component={news}/>
              <UnauthRoute exact path ="/chat" component={chat}/>
              <UnauthRoute exact path ="/posts/:postId" component={home}/>
              <UnauthRoute exact path ="/manage" component={manage}/>
              <UnauthRoute exact path ="/profile" component={profile}/>
              <UnauthRoute exact path ="/admin" component={admin}/>
              <UnauthRoute exact path ="/users/:username" component={user}/>
              <Route exact path ="/terms" component={terms}/>
              <Route exact path ="/privacy" component={privacy}/>
              <AuthRoute exact path ="/login" component={login}/>
              <AuthRoute exact path ="/join" component={join}/>
              <AuthRoute exact path ="/forgot-password" component={forgot_password}/>
              <Route path="*" component={NotFound} />
              
              
            </Switch>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
}
}

// export default App
export default connect(null, { fetchUser })(App);

