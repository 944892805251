import PropTypes from "prop-types";
import { Component } from "react";
import MediaQuery from "react-responsive";
import { mobileMediaQuery } from "../util/Global";

// Redux
import { connect } from "react-redux";
import { getPosts, getUsers } from "../redux/actions/dataActions";
import {
  deleteUser, muteUser, setAsAdmin, unmuteUser, unsetAsAdmin
} from "../redux/actions/userActions";

// My Components
import CreateTag from "../components/CreateTag";
import DeleteTag from "../components/DeleteTag";
import EditTag from "../components/EditTag";
import MobileNavBar from "../components/MobileNavBar";
import NavMenu from "../components/NavMenu";

// MUI
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import withStyles from "@mui/styles/withStyles";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import EditPrivacy from "../components/EditPrivacy";
import EditTerms from "../components/EditTerms";

const styles = (theme) => ({
  ...theme.spreadThis,
  manageTags:{
    display:"flex",
    // justifyContent:"space-between",
    alignItems:"center",
    gap:"20px"
  },
  tagsScreen: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width:"100%"
  },
  tagRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center"
  },
  tagInteraction: {
    display: "flex",
    alignItems:"center",
    gap: "10px",
    color: "#747474",
  },
  notAdminContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100vw",
    "& h1": {
      textAlign: "center",
    },
  },
});

class admin extends Component {
  state = {
    currentTab: "1",
    showActions: false,
    uploadTermsOpen:false,
    uploadPrivacyOpen:false
  };

  handleClick = (event) => this.setState({ navAnchor: event.currentTarget });
  handleClose = () => this.setState({ navAnchor: null });

  componentDidMount() {}

  handleMuteUser = (username) => {
    this.props.muteUser(username);
  };

  handleUnmuteUser = (username) => {
    this.props.unmuteUser(username);
  };

  handleSetAsAdmin = (username) => {
    this.props.setAsAdmin(username);
  };

  handleUnsetAsAdmin = (username) => {
    this.props.unsetAsAdmin(username);
  };

  handleDeleteUser = (username) => {
    this.props.deleteUser(username);
  };

  handleOpenUploadTerms = () => {
    this.setState({uploadTermsOpen:!this.state.uploadTermsOpen})
  }

  handleOpenUploadPrivacy = () => {
    this.setState({uploadPrivacyOpen:!this.state.uploadPrivacyOpen})
  }

  render() {
    const {
      classes,
      user: { admin, authenticated },
      data: { users, tags },
      UI: { loading },
    } = this.props;
    const { showActions } = this.state;

    const handleChange = (event, newValue) => {
      this.setState({ currentTab: newValue });
      if (newValue === "2") {
        this.props.getUsers();
      }
    };

    const userGridRows = [];

    const userGridColumns = [
      { field: "col1", headerName: "Username", width: 150 },
      { field: "col2", headerName: "Display Name", width: 150 },
      { field: "col3", headerName: "Email", width: 200 },
      { field: "col4", headerName: "DOB", width: 100 },
      { field: "col5", headerName: "Karma", width: 100 },
      {
        field: "col6",
        headerName: "Paid",
        width: 75,
        renderCell: (params) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              {params.row.col6 === true ? (
                <CheckIcon color="info" />
              ) : (
                <CloseIcon color="primary" />
              )}
            </div>
          );
        },
      },
      {
        field: "col7",
        headerName: "Muted",
        width: showActions ? 100 : 75,
        renderCell: (params) => {
          return (
            <div>
              {showActions ? (
                <div>
                  {params.row.col7 === true ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.handleUnmuteUser(params.row.col1)}
                    >
                      UNMUTE
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => this.handleMuteUser(params.row.col1)}
                    >
                      MUTE
                    </Button>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  {params.row.col7 === true ? (
                    <CheckIcon color="info" />
                  ) : (
                    <CloseIcon color="primary" />
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: "col8",
        headerName: "Admin",
        width: showActions ? 170 : 75,
        renderCell: (params) => {
          return (
            <div>
              {showActions ? (
                <div>
                  {params.row.col8 === true ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.handleUnsetAsAdmin(params.row.col1)}
                    >
                      UNSET AS ADMIN
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => this.handleSetAsAdmin(params.row.col1)}
                    >
                      SET AS ADMIN
                    </Button>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  {params.row.col8 === true ? (
                    <CheckIcon color="info" />
                  ) : (
                    <CloseIcon color="primary" />
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      // {
      //   field: "col9",
      //   headerName: showActions ? "Delete" : "",
      //   width: showActions ? 150 : 75,
      //   renderCell: (params) => {
      //     return (
      //       <div>
      //         {showActions ? (
      //           <div>
      //             <Button
      //               color="primary"
      //               variant="contained"
      //               onClick={() => this.handleDeleteUser(params.row.col1)}
      //             >
      //               Delete User
      //             </Button>
      //           </div>
      //         ) : (
      //           <div className="d-flex justify-content-between align-items-center"></div>
      //         )}
      //       </div>
      //     );
      //   },
      // },
    ];

    let actionList = <div></div>;

    let userList = !loading ? (
      users.map((user) => {
        let is_subscribed = user.subscriptionTier >= 1 ? true : false;
        let is_muted = user.muted === true ? true : false;
        let is_admin = user.admin === true ? true : false;
        let dob_formatted = user.DOB.split('T')[0]
        return userGridRows.push({
          id: user.userId,
          col1: user.username,
          col2: user.displayName,
          col3: user.email,
          col4: dob_formatted,
          col5: user.karma,
          col6: is_subscribed,
          col7: is_muted,
          col8: is_admin,
        });
      })
    ) : (
      <CircularProgress />
    );

    const handleDeleteTag = (tagId) => {
      this.props.deleteTag(tagId);
    };

    let tagsList = !loading ? (
      tags.reverse().map((tag) => {
        return (
          <div className={classes.tagRow}>
            {/* <Chip
              key={tag.tagId}
              label={tag.name}
              variant="default"
              color="secondary"
              size="medium"
              // className={classes.tag}
            /> */}
            <p>{tag.name}</p>
            <div className={classes.tagInteraction}>
              {/* <Button color='info'><EditIcon/></Button> */}
              <EditTag tagId={tag.tagId} tagName={tag.name} />
              <DeleteTag tagId={tag.tagId} tagName={tag.name} />
              {/* <Button><DeleteIcon onClick={() => handleDeleteTag(tag.tagId)}/></Button> */}
            </div>
          </div>
        );
      })
    ) : (
      <CircularProgress />
    );

    const handleShowActions = () => {
      this.setState({ showActions: !showActions });
    };

    let generalTab = (
      <div className={classes.generalSettings}>
        <h2>General Settings</h2>
        <List>
          {!this.state.uploadTermsOpen &&
          !this.state.uploadPrivacyOpen ? (
            <div>

              <ListItemButton onClick={this.handleOpenUploadTerms}>
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>

                <ListItemText
                  primary="Edit Terms & Conditions Page"
                  secondary="Agreement between the We Go Along Club and a person who wants to use this service."
                />
                <ChevronRightIcon />
              </ListItemButton>

              <ListItemButton onClick={this.handleOpenUploadPrivacy}>
                <ListItemIcon>
                  <GavelIcon />
                </ListItemIcon>

                <ListItemText primary="Edit Privacy Page" 
                secondary="What data the website collects from the user and how that data is used." />
                <ChevronRightIcon />
              </ListItemButton>

            </div>
          ) : null}


          <EditTerms
            open={this.state.uploadTermsOpen}
            handler={this.handleOpenUploadTerms}
          />

          <EditPrivacy
            open={this.state.uploadPrivacyOpen}
            handler={this.handleOpenUploadPrivacy}
          />
        </List>
      </div>
    );

    let usersTab = (
      <div className={classes.usersScreen}>
        <h2>Manage Users</h2>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={showActions} onChange={handleShowActions} />
            }
            label="Show Actions"
          />
        </FormGroup>
        <div style={{ height: 300, width: "100%" }}>
          <DataGrid
            rows={userGridRows}
            columns={userGridColumns}
            disableSelectionOnClick={true}
          />
        </div>
      </div>
    );

    let tagsTab = (
      <div>
        <div className={classes.manageTags}>
        <h2>Manage Tags</h2>
        <CreateTag />
        </div>
        <div className={classes.tagsScreen}>
        {tagsList}
        </div>
      </div>
    );

    return (
      <div>
        {authenticated ? (
          <div>
            {!loading ? (
              <div>
                {admin ? (
                  <div className={classes.mainPageContainer}>
                    <Grid
                      container
                      justifyContent="left"
                      spacing={0}
                      className={classes.mainFeedGridContainer}
                    >
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        display={{ xs: "none", lg: "block" }}
                      >
                        <div className={classes.navMenuContainer}>
                          <NavMenu />
                        </div>
                      </Grid>
                      <Grid
                        item
                        sm={8}
                        xs={12}
                        className={classes.mainFeedItemContainer}
                      >
                        <MediaQuery query={mobileMediaQuery}>
                          <MobileNavBar />
                        </MediaQuery>

                        <Box>
                          <TabContext value={this.state.currentTab}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="General" value="1" />
                                <Tab label="Users" value="2" />
                                <Tab label="Tags" value="3" />
                              </TabList>
                            </Box>
                            <TabPanel value="1">{generalTab}</TabPanel>
                            <TabPanel value="2">{usersTab}</TabPanel>
                            <TabPanel value="3">{tagsTab}</TabPanel>
                          </TabContext>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div className={classes.notAdminContainer}>
                    <h1>
                      Ooops, it doesn't look like you don't have jurisdiction
                      here.
                    </h1>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

admin.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getPosts: PropTypes.func.isRequired,
  muteUser: PropTypes.func.isRequired,
  unmuteUser: PropTypes.func.isRequired,
  setAsAdmin: PropTypes.func.isRequired,
  unsetAsAdmin: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getPosts,
  getUsers,
  muteUser,
  unmuteUser,
  setAsAdmin,
  unsetAsAdmin,
  deleteUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(admin));
