import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { mobileMediaQuery } from '../util/Global';

// Images
import Icon from '../images/Mountain Logo Long.png';
import WelcomeBackground from '../images/WelcomeBackground.jpeg';

// Redux
import { connect } from 'react-redux';

// MUI
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';



const styles = (theme) => ({
  ...theme.spreadThis,

  welcome: {
    overflow: 'hidden',
    position: 'absolute',
    top: '0px',
  },

  welcomeContainer: {
    minHeight: '100%',
  },

  background: {
    backgroundImage: `url(${WelcomeBackground})`,
    backgroundSize: 'cover',
    height: "100vh",
    width: "100%",
  },

  blur: {
    backdropFilter: "blur(1.5px)", // This be the blur
    height: '100vh',
    width: '100vw',
  },

  welcomeGridContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    overflow: 'hidden',
  },

  welcomeStatementActionsContainer: {
    background: "rgb(255,255,255,0.65)",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;",
    borderRadius: "15px",
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    height: "450px",
    width: "500px",
    
      [`@media ${mobileMediaQuery}`]: { 
        height: "auto",
        width: "auto",
        margin: "10px"
      },
  },

  welcomeStatement: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '45px',
    padding: '20px 0px',
    

    '& h1:nth-child(1)': {
      color: '#3a4387',
      fontSize: '28px',
      lineHeight: "0",
      margin: '0px',
      padding: "0px",
      [`@media ${mobileMediaQuery}`]: { 
        fontSize: '30px',
        lineHeight: "1.5px",
      },
    },
    '& h1:nth-child(2)': {
      color: '#3a4387',
      fontSize: '45px',
      lineHeight: "0",
      margin: '0px',
      padding: "0px",
      [`@media ${mobileMediaQuery}`]: { 
        fontSize: '45px',
      },
    },
    '& h1:nth-child(3)': {
      color: '#e64f51',
      fontSize: '60px',
      lineHeight: "0",
      margin: '0px',
      padding: "0px",
      [`@media ${mobileMediaQuery}`]: { 
        fontSize: '45px',
      },
    },
    '& p': {
      color: '#1c1d1d',
      fontSize: '18px',
      fontWeight: 'bold',
      maxWidth: '500px',
      margin: 0,
      padding:'0px 20px'
    },
  },

  iconType: {
    textAlign: "center",
    width: "350px"
  },

  welcomeType: {
    textAlign: "center",
    width: "250px"
  },

  welcomeButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
  },

  statementButtonsGap: {
    display: "flex",
    flexDirection: "column",
    gap: 20
  },

  welcomeIcon: {
    position: 'absolute',
    bottom: '10px',
    right: '0px',
    height: '50px',
    opacity: "85%",
    [`@media ${mobileMediaQuery}`]: { 
      height: '25px',
    },
  },

  welcomeFooter: {
    position: 'relative',
    bottom: '10px',
  },

  welcomeFooterButtons: {
    color: "#1c1d1d",
    fontWeight: "bold",
  }
})


export class welcome extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.background}>
        <div className={classes.blur}></div>
        <div className={classes.welcome}>
        <CSSTransition
          classNames="fade"
          in={true}
          appear={true}
          timeout={800}
          >
          <div className={classes.welcomeContainer}>
            <div className={classes.welcomeGridContainer}>
              <div className={classes.welcomeStatementActionsContainer}>
                <div></div>
                <div className={classes.statementButtonsGap}>
                  <div className={classes.welcomeStatement}>
                    {/* <img src={WelcomeTypeIcon} alt="Icon" className={classes.welcomeType}/> */}
                    <h1>Welcome to the </h1>
                    {/* <img src={ClubTypeIcon} alt="Icon" className={classes.iconType}/> */}
                    <h1>We Go Along</h1>
                    <h1>Club</h1>
                    <p>Home to exclusive content, news, merch, giveaways, polls, chats & more.</p>
                  </div>

                  <div className={classes.welcomeButtonsContainer}>
                    <Button className={classes.welcomeButtons} variant="contained" color="primary" size="large" component={Link} to="/join" disableElevation>Join</Button>
                    <Button className={classes.welcomeButtons} variant="contained" color="secondary" size="large" component={Link} to="/login" disableElevation>Login</Button>

                  </div>
                </div>
                <div className={classes.welcomeIcon}>
                  <img src={Icon} alt="Icon" className={classes.welcomeIcon} />
                </div>

                <div className={classes.welcomeFooter}>
                  <Button className={classes.welcomeFooterButtons} variant="text" href="https://wegoalong.com">WeGoAlong.com</Button>
                  {/* <Button className={classes.welcomeFooterButtons} variant="text" component={Link} to="/tour">Tour</Button> */}
                  <Button className={classes.welcomeFooterButtons} variant="text" component={Link} to="/terms">Terms</Button>
                  <Button className={classes.welcomeFooterButtons} variant="text" component={Link} to="/privacy">Privacy</Button>
                </div>
              </div>
            </div>
          </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {};

welcome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(welcome));
