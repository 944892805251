import {
  LIKE_POST, LOADING_USER, SET_ADMIN, SET_AUTHENTICATED,
  SET_CURRENT_USER, SET_UNAUTHENTICATED, SET_USER, UNLIKE_POST,
  VOTE_POLL
} from '../types';

const initialState = {
  authenticated: null,
  admin: false,
  currentUser: null,
  credentials : {},
  likes: [],
  votes: [],
  loading: false
};

export default function(state=initialState,action){
  switch(action.type){
   case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload || null
      }
    case SET_UNAUTHENTICATED:
      return {
        ...initialState,
        authenticated: false
      }
        
    case SET_ADMIN:
      return {
        ...state,
        admin: true
      };
    case SET_USER:
        return {
          ...state,
          authenticated: true,
          loading: false,
          ...action.payload
        };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LIKE_POST:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
          userUsername: state.credentials.username,
          postId: action.payload.postId
          }
        ]
      };
    case UNLIKE_POST:
      return {
        ...state,
        likes: state.likes.filter(like => like.postId !== action.payload.postId)
      };
      case VOTE_POLL:
        return {
          ...state,
          votes: [
            ...state.votes,
            {
            userUsername: state.credentials.username,
            postId: action.payload.postId,
            voteOption: action.payload.voteOption,
            }
          ]
        };
    default:
      return state;
  }
}