import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import configDevPub from './configFiles/configDevPub';
import configProdPub from './configFiles/configProdPub';
// {

const app = initializeApp(configProdPub)

export const auth = getAuth(app);

if (typeof window !== 'undefined') {
    const firebaseAnalytics = require('firebase/analytics');
    const firebasePerformance = require('firebase/performance');
    const perf = firebasePerformance.getPerformance(app);
    const analytics = firebaseAnalytics.getAnalytics(app);
  }


export default app
