import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import MediaQuery from 'react-responsive';
import { mobileMediaQuery } from '../util/Global';

// Redux Stuff
import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { uploadImage } from '../redux/actions/userActions';

// My Components
import EditProfileDetails from '../components/EditProfileDetails';
import MobileNavBar from '../components/MobileNavBar';
import NavMenu from '../components/NavMenu';
import StaticProfile from '../components/StaticProfile';

// MUI Stuff
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

// Icons

const styles = (theme) => ({
  ...theme.spreadThis,
  error: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    fontWeight:"bold",
    marginLeft: "14px",
    marginTop: "3px",
  },
})

export class profile extends Component {

  componentWillUnmount(){
    this.props.clearErrors()
  }
  handleImageChange = (event) => {
    this.props.clearErrors()
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    this.props.uploadImage(formData,image.size);
    
  };
  handleEditPicture = () => {
    this.props.clearErrors();
    const fileInput = document.getElementById('imageInput');
    fileInput.click();
  };
  render() {
    const { classes, user: {loading, authenticated }, UI:{errors}} = this.props;
    const { user } = this.props;

    let profile = !loading ? (authenticated ? (
      <div>
        <CSSTransition
          classNames="quickFade"
          in={!loading}
          appear={true}
          timeout={600}
          >
      <StaticProfile profile={user.credentials}/>
      </CSSTransition>
        <div className="side-by-side-buttons">
          <input type="file" id="imageInput" hidden="hidden" accept=".jpg,.png"  onChange={this.handleImageChange}/>
        <EditProfileDetails/>
        <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.buttonSecondary}
              onClick={this.handleEditPicture}
              disableElevation
            >
              Upload Image
              </Button>
              </div>
              {errors &&
              <div className='center'>
            {errors.fileSize && <p className={classes.error}>{errors.fileSize}</p>}
            {errors.general && <p className={classes.error}>{errors.general}</p>}
            </div>
              }
              
              
              </div>
              
    ) : (
      <Paper className={classes.paper}>
        <Typography varient="body2" align="center"> No profile found, please login again.</Typography>
        <div className={classes.buttons}>
          <Button varient="contained" color="primary" component={Link} to="/login">Login</Button>
          <Button varient="contained" color="primary" component={Link} to="/join">Join</Button>
        </div>
      </Paper>
      
    )) : (
      <div className="center" style={{paddingTop:200}} key={0}><CircularProgress size={60} thickness={6.5} color="secondary"/></div>  

    )

    return (
      <div>
     {authenticated ? (
         <div className={classes.mainPageContainer}>
         <Grid container justifyContent="left" spacing={0} className={classes.mainFeedGridContainer}>
           <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
             <div className={classes.navMenuContainer}>
               <NavMenu />
             </div>
           </Grid>

           <MediaQuery query={mobileMediaQuery}>
           <MobileNavBar />
           </MediaQuery>

              <Grid item sm={8} xs={12} className={classes.mainFeedItemContainer}>
                
                {profile}
              </Grid>
            </Grid>
          </div>
        ) : (null)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = { uploadImage,clearErrors };

profile.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  clearErrors:PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(profile));
