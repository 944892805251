import PropTypes from 'prop-types';
import { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import MediaQuery from 'react-responsive';
import { editUserDetails } from '../redux/actions/userActions';
import { mobileMediaQuery } from '../util/Global';

// Images

// Redux
import { connect } from 'react-redux';

// My Components
import MobileNavBar from '../components/MobileNavBar';
import NavMenu from '../components/NavMenu';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import withStyles from '@mui/styles/withStyles';

// Icons

const styles = (theme) => ({
  ...theme.spreadThis,
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 15,
    paddingBottom: 20,
  },
  chatDetails: {
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    gap:5,
    padding: 20,
    
    "& h1": {
      textAlign:"center",
      color: "#5865F2",
      fontSize: 40,
      padding: 0,
      margin: 0,
      
    },
    "& h2": {
      textAlign:"center",
      color: "#1c1d1d",
      fontFamily: "Rust",
      fontSize: 30,
      padding: 0,
      margin: 0,
    },
    p:{
      textAlign:"left",
      
    }
  },
  logo: {
    height: 75,
    width: 75,
    [`@media ${mobileMediaQuery}`]: { 
      height: 80,
    width: 80,
    },
    borderRadius: "500px",
    boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  icon: {
    height: 45,
    width: 45,
    color: "#938d94",
  },
  chatExplainerContainer:{
    padding:"30px 30px",
    background:"#f4f6f6",
    borderRadius:15,

  },
  chatRulesList:{
    
    listStyleType: "circle",
    textAlign:"left",
    lineHeight:"20px",
    '& li':{
      display: "list-item",
        listStyleType: "numbered",
        padding:"5px 0px",
        textAlign:"left"
    }
  },

  goButtonContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    ['@media (max-width: 550px)']: { 
      flexDirection:"column",
      gap:20
    },
  },
  discordUsernameTextfieldContainer:{
    position:"relative",
    
  },
  discordUsernameTextfield:{
    width:"250px"
  },
  submitButton:{
    position: "absolute",
    bottom: "10px",
    right: "2px",
    ['@media (max-width: 550px)']: { 
      position: "relative",
      color: "#fff",
      background: "#e64f51",
      padding: "10px 20px",
      marginTop: "20px",
    },
  },
  
  goButton: {
    background: "#5865F2",
    fontWeight: "bold",
    borderRadius:"5px",
    // maxWidth:200,
    width:"fit-content",
    textAlign:"center",
    "&:hover": {
      background: "#404EED",
    }
  },

  discordUsername:{
    fontSize:"12px",
    margin:0,
    padding:0,
    paddingLeft:2,
    ['@media (max-width: 550px)']: { 
      textAlign: "center",
      paddingTop:"20px"
    },
  }

})

export class chat extends Component {

  constructor() {
    super();
    this.state = {
     discordUsername:""
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };


  submitDiscordUsername = (event) => {
    event.preventDefault();
    const userData = {
      discordUsername: this.state.discordUsername.toLowerCase().trim()
    };
    this.props.editUserDetails(userData);
  };

  render() {
    const { classes, user: { credentials: { username, displayName, discordUsername, createdAt, imageUrl, bio, karma, location }, authenticated }, UI:{errors, loading} } = this.props;
    const userLoading = this.props.user.loading;
    let chatMarkup = !loading ? (


      <Box m={0} pt={0}>
        <div></div>
          <div className={classes.chat}>


            <div className={classes.chatDetails}>

              
              
              <div className={classes.chatExplainerContainer}>
              <h2>We Go Along Club</h2>
              <h1>Discord Server</h1>
              
              <p>Welcome to the We Go Along Club Chat, where we chat about anything and everything. From keeping the conversation going after the TikTok Lives, to discussing upcoming events, and of course sharing funny memes.</p>
              <h3>Club Chat Rules</h3>
              <ul className={classes.chatRulesList}>
                
                <li>Treat everyone with kindness and respect. We have zero-tolerance for harassment, sexism, racism, hate speech in this server.</li>
                <li>Be civil and respectful. Avoid discussing politically inflammatory content or sharing anything intended or likely to cause upset or offence.</li>
                <li>No spamming. Spamming is disruptive and meaningless. Spammers will be banned immediately without warning.</li>
                <li>Do not post personally identifiable information of others, or threaten to doxx. This also includes any private messages/direct messages with names not blocked out.</li>
                <li>Use the appropriate channels for their respective purposes.</li>
                <li>No NSFW images or illegal content.</li>
                <li>No advertising in general chat rooms. There are dedicated channels for promoting.</li>
                <li>Do not post any kind of spam or phishing/scam sites.</li>
              </ul>

              <h3>How to Join</h3>
              <ul className={classes.chatRulesList}>
                
                <li>Head over to <a href="https://discordapp.com/register">Discord.com</a> to create your account. If you already have an account, move on to the next step.</li>
                <li>Enter your Discord username in the text field below and press Submit. Enter your username as it is displayed on Discord without the extra numbers it assigns you (ex. #2405).</li>
                <li>Click the "Get My WGA Club Discord Link" Button to be redirected to our server.</li>
                <li>Read and Accept our Chat Policy. You will need to verify your email with Discord before joining.</li>
                <li>In the #verification room, press the green checkmark under the opening message. The bot will verify that your Discord username matches the one you had entered in the textbox below, and will verify you as a paying member.</li>
                <li>Look for a DM from the Verification Bot that tells you if the verification was succesful or not.</li>
              </ul>
              <div className={classes.goButtonContainer}>

            <div className={classes.discordUsernameTextfieldContainer}>
              <TextField
                id="discordUsername"
                name="discordUsername"
                type="text"
                label="Discord Username"
                color="info"
                className={classes.discordUsernameTextfield}
                helperText={errors.discordUsername}
                error={errors.discordUsername ? true : false}
                value={this.state.discordUsername}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
              />
              
              <Button className={classes.submitButton} color="info" variant="text" onClick={this.submitDiscordUsername} disabled={this.state.discordUsername.length < 2 || userLoading} elevation={0} >
                Submit
                {userLoading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
              </Button>
              </div>
              { discordUsername &&
              <Button className={classes.goButton} variant="contained" href="https://discord.gg/CD5FT7TAKQ" target="_blank" elevation={5} fullWidth>Get My WGA Club Discord Link</Button>
            }
            </div>
            <p className={classes.discordUsername}>User: {discordUsername}</p>
              </div>
              {/* <div className={classes.logoContainer}>
                <img src={WeGoAlongLogo} alt="Icon" className={classes.logo} />
                <MultIcon className={classes.icon} />
                <img src={DiscordLogo} alt="Icon" className={classes.logo} />
              </div> */}
            </div>

          </div>
      </Box>
    ) : (null)

    return (
      <div>
        {authenticated ? (
         <div className={classes.mainPageContainer}>
         <Grid container justifyContent="left" spacing={0} className={classes.mainFeedGridContainer}>
           <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
             <div className={classes.navMenuContainer}>
               <NavMenu />
             </div>
           </Grid>

           <MediaQuery query={mobileMediaQuery}>
           <MobileNavBar />
           </MediaQuery>

              <Grid item sm={8} xs={12} className={classes.mainFeedItemContainer}>
              <CSSTransition
          classNames="quickFade"
          in={!loading}
          appear={true}
          timeout={600}
          >
                {chatMarkup}
                </CSSTransition>
              </Grid>
            </Grid>
          </div>
        ) : (null)}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
editUserDetails
};

chat.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editUserDetails: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(chat));
