import axios from 'axios';
import { convertFromRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { home_types_array, news_types_array } from '../../util/Global';
import { CLEAR_CREATE_POST_SUCCESS, CLEAR_ERRORS, CREATE_POST, CREATE_TAG, DELETE_COMMENT, DELETE_POST, DELETE_TAG, LIKE_POST, LOADING_DATA, LOADING_UI, SET_ERRORS, SET_FILTER, SET_MORE_COMMENTS, SET_MORE_POSTS, SET_POST, SET_POSTS, SET_POST_INITIAL, SET_PRIVACY, SET_TERMS, SET_USERS, SET_USER_DISPLAY, STOP_LOADING_DATA, STOP_LOADING_UI, SUBMIT_COMMENT, UNLIKE_POST, VOTE_POLL } from '../types';

axios.defaults.baseURL = '/api';

export const getPosts = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/home')
  .then(res => {
    dispatch({
      type: SET_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_POSTS,
        payload: {posts: [],
          lastKey: null,
          tags: []}
      })
    })
};

export const loadMorePosts = (lastKey) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  
  axios.get(`/loadMorePosts/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_POSTS,
        payload: []
      })
    })
};

export const getFilteredPosts = (filterData) => dispatch => {
  dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_tag":filterData.FILTER_tag,
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get('/homeFiltered',filterDataPost)
  .then(res => {
    dispatch({
      type: SET_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_POSTS,
        payload: {posts: [],
          lastKey: null,
          tags:[]}
      })
    })
};

export const loadMoreFilteredPosts = (lastKey,filterData) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_tag":filterData.FILTER_tag,
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get(`/loadMoreFilteredPosts/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_POSTS,
        payload: []
      })
    })
};

export const getNews = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/news')
  .then(res => {
    dispatch({
      type: SET_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_POSTS,
        payload: {posts: [],
          lastKey: null,
          tags:[]}
      })
    })
};

export const loadMoreNews = (lastKey) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  
  axios.get(`/loadMoreNews/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_POSTS,
        payload: []
      })
    })
};

export const getFilteredNews = (filterData) => dispatch => {
  dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get('/newsFiltered',filterDataPost)
  .then(res => {
    dispatch({
      type: SET_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      const status = err.response.status
      if (status === 403){
        dispatch({
          type: SET_ERRORS,
          payload: {unsubscribed:"UnsubscribedError"}
        })
      } 
      else if (status === 500){
        dispatch({
          type: SET_ERRORS,
          payload: {data:"DataLoadingError"}
        })
      }
      
      dispatch({
        type: SET_POSTS,
        payload: {posts: [],
          lastKey: null,
          tags:[]}
      })
    })
};

export const loadMoreFilteredNews = (lastKey,filterData) => dispatch => {
  // dispatch({ type: LOADING_DATA });
  let filterDataPost = {
    params:{
    "FILTER_dateStart":filterData.FILTER_dateStart,
    "FILTER_dateEnd":filterData.FILTER_dateEnd,
    "FILTER_type":filterData.FILTER_type
    }
  }
  
  axios.get(`/loadMoreFilteredNews/${lastKey}`)
  .then(res => {
    dispatch({
      type: SET_MORE_POSTS,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_POSTS,
        payload: []
      })
    })
};


export const setFilterOptions = (filterOptions) => dispatch => {
    dispatch({
      type: SET_FILTER,
      payload: {filterOptions}
    })
     
};

export const getPost = (postId) => dispatch => {
  dispatch({ type: LOADING_UI });
  // dispatch({ type: LOADING_COMMENTS });
  axios.get(`/post/${postId}`)
  .then(res => {
    dispatch({
      type: SET_POST,
      payload: res.data
    })
    dispatch({type: STOP_LOADING_UI})
    // dispatch({type: STOP_LOADING_COMMENTS})
  })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    })
};

export const loadMoreComments = (lastKeyComment,postId) => dispatch => {
  
  // dispatch({ type: LOADING_COMMENTS });
  axios.get(`/loadMoreComments/${lastKeyComment}/${postId}`)
  .then(res => {
    dispatch({
      type: SET_MORE_COMMENTS,
      payload: res.data
    });
    // dispatch({type: STOP_LOADING_COMMENTS})
  })
    .catch(err => {
      dispatch({
        type: SET_MORE_COMMENTS,
        payload: []
      })
      // dispatch({type: STOP_LOADING_UI})
    })
};

export const setPostInitial = (postId) => dispatch => {
    dispatch({
      type: SET_POST_INITIAL,
      payload: {postId}
    })
};

export const clearPost = () => dispatch => {
  dispatch({
    type: SET_POST,
    payload: {}
  });
};

export const getUsers = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get('/users')
  .then(res => {
    dispatch({
      type: SET_USERS,
      payload: res.data
    });
  })
    .catch(err => {
      dispatch({
        type: SET_USERS,
        payload: []
      })
    })
};

export const likePost = (postId) => dispatch => {
  dispatch({
    type: LIKE_POST,
    payload: {postId,addition:1}
  })
  axios.get(`/post/${postId}/like`)
  // .then(res => {
    
  // })
  .catch(err => {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  })
};

export const unlikePost = (postId) => dispatch => {
  dispatch({
    type: UNLIKE_POST,
    payload: {postId,addition:-1}
  })
  axios.get(`/post/${postId}/unlike`)
  .then(res => {
    
    
  })
  .catch(err => {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  })
};

export const submitComment = (postId, commentData) => (dispatch) => {
  axios
    .post(`/post/${postId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

export const deleteComment = (postId,commentId) => (dispatch) => {
  axios
    .delete(`/post/${postId}/${commentId}`)
    .then(() => {
      dispatch({ type: DELETE_COMMENT, payload: {postId,commentId} });
    })
    .catch((err) =>  
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const votePoll = (postId,voteOption) => dispatch => {
  axios.post(`/post/${postId}/vote`,{voteOption})
  .then(res => {
    dispatch({
      type: VOTE_POLL,
      payload: {
        postId:postId,
        voteOption:voteOption
      }
    })
  })
  .catch(err => {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  })
};

export const deletePost = (postId) => (dispatch) => {
  axios
    .delete(`/post/${postId}`)
    .then(() => {
      dispatch({ type: DELETE_POST, payload: postId });
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    })
    )
};

export const createPost = (newPost) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  let newPostFinal = newPost;
  if (newPost.type === "photo" & newPost.formData !== null){
    delete(newPostFinal.pollOptions,newPostFinal.date)
    axios
    .post('/uploadImages',newPost.formData)
    .then((res) => {
      
      let new_dataURLs = [];
      for (let i = 0; i < res.data.imageNames["length"]; i++) {
        new_dataURLs.push(res.data.imageNames[i]);
    }
    newPostFinal["dataURLs"] = new_dataURLs;
    })
    .then((res) => {
      axios
      .post('/createPost', newPostFinal)
    })
    .then((res) => {
      dispatch({
        type: CREATE_POST,
        payload: newPostFinal
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }
  else if (newPost.type === "video" & newPost.formData !== null){
    delete(newPostFinal.pollOptions,newPostFinal.date)
    let new_dataURLs = [];
      axios
      .post('/uploadImages',newPost.formData)
    .then((res) => {
      new_dataURLs.push({"video":newPost.videoURL,"thumbnail":res.data.imageNames[0]})
      newPostFinal["dataURLs"] = new_dataURLs;
      })
    .then((res) => {
      axios
      .post('/createPost', newPostFinal)
    })
    .then((res) => {
      dispatch({
        type: CREATE_POST,
        payload: newPostFinal
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }
  else if (newPost.type === "article" & newPost.formData !== null){
    delete(newPostFinal.pollOptions,newPostFinal.date)
    let new_dataURLs = [];
      axios
      .post('/uploadImages',newPost.formData)
    .then((res) => {
      new_dataURLs.push({"thumbnail":res.data.imageNames[0]})
      newPostFinal["dataURLs"] = new_dataURLs;
      })
    .then((res) => {
      axios
      .post('/createPost', newPostFinal)
    })
    .then((res) => {
      dispatch({
        type: CREATE_POST,
        payload: newPostFinal
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }
  else if (newPost.type === "poll" & newPost.pollOptions !== null){
    let newPollOptions = {}
    Object.keys(newPost.pollOptions).map((key, index) => {
     newPollOptions[`option${key}`] = {name:newPost.pollOptions[key],count:0}
   })
   newPollOptions["endDate"] = new Date(newPost.date).toISOString();
   newPostFinal["pollOptions"] = newPollOptions;
    axios
    .post('/createPost', newPostFinal)
  
  .then((res) => {
    dispatch({
      type: CREATE_POST,
      payload: newPostFinal
    });
    dispatch(clearErrors());
  })
  .catch((err) => {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  });
  }
  else{
    delete(newPostFinal.pollOptions,newPostFinal.date)
    axios
      .post('/createPost', newPostFinal)
    .then((res) => {
      dispatch({
        type: CREATE_POST,
        payload: newPostFinal
      });
      dispatch(clearErrors());
    })
    .then((res) => {
      if (newPost.type in home_types_array){dispatch(getPosts());}
      else if (newPost.type in news_types_array){dispatch(getNews());}
    })
    .catch((err) => {

      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
  }
  

};

export const clearCreatePostSuccess = () => (dispatch) => {
 
      dispatch({
        type: CLEAR_CREATE_POST_SUCCESS
      });
    
};

export const createTag = (newTag) => (dispatch) => {
  // dispatch({ type: LOADING_UI });
  axios
    .post('/createTag', newTag)
    .then((res) => {
      dispatch({
        type: CREATE_TAG,
        payload: newTag
      });
      // dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

export const getUserData = (userUsername) => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios.get(`/user/${userUsername}`)
  .then((res) => {
    dispatch({
      type: SET_USER_DISPLAY,
      payload: res.data
    });
  })
  .then(() => {
    dispatch({
      type: STOP_LOADING_DATA
    });
  })
  .catch((err) => {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  });
  
}


export const editPostDetails = (postDetails) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post(`/post/${postDetails.postId}`, postDetails)
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const editTagDetails = (tagId,tagName) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post(`/tag/${tagId}`, {name:tagName})
    .then(() => {
      dispatch(getPosts());
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};

export const deleteTag = (tagId) => (dispatch) => {
  axios
    .delete(`/tag/${tagId}`)
    .then(() => {
      dispatch({ type: DELETE_TAG, payload: tagId });
      return 
    })
    .catch((err) => 
     dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};



export const getTerms = (terms) => (dispatch) => {
  let termsReturn 
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/documents/terms`)
    .then((res) => {
      termsReturn = res.data;
      const DBEditorState = JSON.parse(termsReturn.content);
      const contentState = convertFromRaw(DBEditorState);
      const editorState = EditorState.createWithContent(contentState);
      dispatch({
        type: SET_TERMS,
        payload: editorState
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
    
};


export const uploadTerms = (terms) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post(`/documents/terms`, terms)
    .then(() => {
      dispatch(getTerms());
    })
    .catch((err) => 
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};


export const getPrivacy = (privacy) => (dispatch) => {
  let privacyReturn 
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/documents/privacy`)
    .then((res) => {
      privacyReturn = res.data;
      const DBEditorState = JSON.parse(privacyReturn.content);
      const contentState = convertFromRaw(DBEditorState);
      const editorState = EditorState.createWithContent(contentState);
      dispatch({
        type: SET_PRIVACY,
        payload: editorState
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      })
    });
    
};


export const uploadPrivacy = (privacy) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .post(`/documents/privacy`, privacy)
    .then(() => {
      dispatch(getPrivacy());
    })
    .catch((err) =>  
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }));
};




export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};