
import {
  CLEAR_CREATE_POST_SUCCESS, CREATE_POST,
  CREATE_POST_ERROR, CREATE_TAG, DELETE_COMMENT, DELETE_POST,
  DELETE_TAG, LIKE_POST, LOADING_COMMENTS, LOADING_DATA, SET_FILTER, SET_MORE_COMMENTS, SET_MORE_POSTS, SET_POST, SET_POSTS, SET_POST_INITIAL, SET_PRIVACY, SET_TERMS, SET_USERS,
  SET_USER_DISPLAY, STOP_LOADING_COMMENTS, STOP_LOADING_DATA, SUBMIT_COMMENT, UNLIKE_POST,
  VOTE_POLL
} from '../types';

const initialState = {
  posts: [],
  lastKey:null,
  lastKeyComment:null,
  filterOptions: {
      "FILTER_tag":"",
      "FILTER_tagId":"",
      "FILTER_dateStart":"",
      "FILTER_dateEnd":"",
      "FILTER_type":""
  },
  tags: [],
  users:[],
  post: {},
  user: {},
  documents:{terms:null,privacy:null},
  loading: false,
  loadingComments:false,
  createPostSuccess:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
      case STOP_LOADING_DATA:
      return {
        ...state,
        loading: false
      };
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload.posts,
        lastKey: action.payload.lastKey,
        tags: action.payload.tags,
        loading: false
      };
      case SET_MORE_POSTS:
      return {
        ...state,
        posts: state.posts.concat(action.payload.posts),
        lastKey: action.payload.lastKey,
        loading: false
      };
      case SET_POST_INITIAL:
        let post_index = state.posts.findIndex(
          (post) => post.postId === action.payload.postId
        );
        if (post_index !== -1){
    let thisPost = state.posts[post_index]
        return {
          ...state,
          post: thisPost,
        };
      } else {
        return{
          ...state,
        }
      }
    case SET_POST:
      return {
        ...state,
        post: action.payload.post,
        lastKeyComment: action.payload.lastKeyComment,
        loading: false
      };
      case SET_MORE_COMMENTS:
      let new_post_with_comments = state.post;
      let new_post_with_comments_comments;
      if (new_post_with_comments["comments"] !== undefined) {
        new_post_with_comments_comments  = new_post_with_comments.comments.concat(action.payload.comments)
        new_post_with_comments.comments = new_post_with_comments_comments
      } else{
        new_post_with_comments.comments = action.payload.comments
      }
      return {
        ...state,
        post: new_post_with_comments,
        lastKeyComment: action.payload.lastKeyComment,
        loading: false
      };
      case LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: true
      };
      case STOP_LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: false
      };
      case SET_USERS:
        return {
          ...state,
          users: action.payload.users,
          loading: false
        };
        case SET_USER_DISPLAY:
          return {
            ...state,
            user: action.payload
          };
    case SET_FILTER:
      return {
        ...state,
        filterOptions: action.payload.filterOptions,
        // loading: true
      };
    case LIKE_POST:
    case UNLIKE_POST:
      let index = state.posts.findIndex(
        (post) => post.postId === action.payload.postId
      );
      state.posts[index].likeCount += action.payload.addition;
      if (state.post.postId === action.payload.postId) {
        state.post.likeCount += action.payload.addition;
      }
      return {
        ...state
      };

    
        case VOTE_POLL:
          let vote_index = state.posts.findIndex(
            (post) => post.postId === action.payload.postId
          );
          state.posts[vote_index].pollOptions[action.payload.voteOption].count += 1;
          return {
            ...state
          };


      case DELETE_POST:
        let delete_index = state.posts.findIndex(
          (post) => post.postId === action.payload
        );
        state.posts.splice(delete_index, 1);
        return {
          ...state
        };

        case DELETE_TAG:
        let delete_tag_index = state.tags.findIndex(
          (tag) => tag.tagId === action.payload
        );
        state.tags.splice(delete_tag_index, 1);
        return {
          ...state
        };

    case CREATE_POST:
      
        return {
          ...state,
          posts:[
            action.payload,
            ...state.posts
          ],
          createPostSuccess:true
        };

        case CREATE_POST_ERROR:
          return {
            ...state,
            createPostSuccess:false
          };

        case CLEAR_CREATE_POST_SUCCESS:
          return {
            ...state,
            createPostSuccess:null
          };


        case SUBMIT_COMMENT:
          let comment_index = state.posts.findIndex(
            (post) => post.postId === action.payload.postId
          );
          state.posts[comment_index].commentCount += 1;
          if (state.post.postId === action.payload.postId) {
            state.post.commentCount += 1;
          }
      return {
        ...state,
        post: {
          ...state.post,
          comments: [action.payload, ...state.post.comments]
        }
      };

      case DELETE_COMMENT:
        let delete_comment_index = state.post.comments.findIndex(
          (comment) => comment.commentId === action.payload.commentId
        );
        let posts_delete_comment_index = state.posts.findIndex(
          (post) => post.postId === action.payload.postId
        );
        state.post.comments.splice(delete_comment_index, 1);
        state.posts[posts_delete_comment_index].commentCount -= 1;
          if (state.post.postId === action.payload.postId) {
            state.post.commentCount -= 1;
          }
        return {
          ...state,
          post: {
            ...state.post,
            comments: [...state.post.comments]
          }
        };

    case CREATE_TAG:
      return {
        ...state,
        tags:[
          action.payload,
          ...state.tags
        ]
        // loading: false
      };
      case SET_TERMS:
        let new_documents = state.documents
        new_documents.terms = action.payload
        return {
          ...state,
          documents:new_documents
          // loading: false
        };
        case SET_PRIVACY:
          let new_documents2 = state.documents
          new_documents2.privacy = action.payload
          return {
            ...state,
            documents:new_documents2
            // loading: false
          };
    

        
    default:
      return state;
  }
}