import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

import { connect } from "react-redux";
import { editPostDetails, getPost } from "../redux/actions/dataActions";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { mobileMediaQuery } from "../util/Global";

// MUI Stuff
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Icons
import MediaQuery from "react-responsive";

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10,
  },
  progressSpinner: {
    position: "absolute",
  },
  closeButton: {
    // position: 'absolute',
    // left: '91%',
    // top: '4%'
  },
  editPostDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    margin: "20px",
    [`@media ${mobileMediaQuery}`]: {
      margin: "5px",
    },
  },
  editPostForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    gap: "20px",
    overflowX:"hidden",
    padding:"10px 0px",
    width:"100%",
  },
  latlon: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  navMenuItem: {
    textAlign: "center",
    TextDecoder: "none",
    fontSize: "18px",
    backgroundColor: "#f4f6f6",
    borderRadius: "50px",
    marginRight: 12,
  },
  navMenuItemIcon: {
    marginLeft: "22px",
    marginRight: "24px",
    fontSize: "24px",
  },
  textEditorWrapper: {
    maxWidth: "1000px",
    objectPosition: "center",
  },
  textEditor: {
    minHeight: "300px",
    border: "1px #dfe4ea solid",
    borderRadius: "15px",
    padding: "20px 50px",
  },
  textEditorToolbar: {},
  error: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    marginLeft: "14px",
    marginTop: "3px",
  },
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const post_types = ["article", "photo", "text", "video"];
const news_types = ["announce", "poll"];

const emptyArticleContent = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Initialized from content state.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};
const articleContentState = convertFromRaw(emptyArticleContent);

class EditPostDetails extends Component {
  state = {
    open: false,
    maxWidth: "sm",
    errors: {},
    postId: "",
    type: "",
    content: "",
    location: "",
    lat: "",
    lon: "",
    tag: "",
    articleContent: "",
    articleEditorState: articleContentState,
    videoURL: "",
    dataURLs: [],
  };

  mapPostDetailsToState = (post) => {
    this.setState({
      type: post.type ? post.type : "",
      content: post.content ? post.content : "",
      location: post.location ? post.location : "",
      lat: post.locationCoords ? post.locationCoords[0] : "",
      lon: post.locationCoords ? post.locationCoords[1] : "",
      tag: post.tag ? post.tag : "",
      articleContent: post.articleContent ? post.articleContent : "",
      videoURL: post.type === "video" ? post.dataURLs[0]["video"] : "",
      dataURLs: post.dataURLs ? post.dataURLs : "",
    });

    if (post.type === "article") {
      const DBEditorState = JSON.parse(post.articleContent);
      let contentState = convertFromRaw(DBEditorState);
      let editorState = EditorState.createWithContent(contentState);
      this.setState({
        articleEditorState: editorState,
      });
    }
  };
  handleOpen = () => {
    const { postId } = this.props;
    this.props.getPost(postId);
    this.setState({ postId, open: true });
    this.mapPostDetailsToState(this.props.data.post);
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.data.post.postId !== prevProps.data.post.postId) {
      this.mapPostDetailsToState(this.props.data.post);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setTag = (event) => {
    this.setState({ tag: event.target.value });
  };

  onEditorStateChange = (articleEditorState) => {
    this.setState({
      articleEditorState,
    });
  };

  handleSubmit = () => {
    if (this.state.type === "video") {
      this.state.dataURLs[0]["video"] = this.state.videoURL;
      this.setState({ dataURLs: this.state.dataURLs });
    }
    const postDetails = {
      postId: this.state.postId,
      content: this.state.content,
      location: this.state.location,
      locationCoords: [this.state.lat, this.state.lon],
      tag: this.state.tag,
      articleContent:
        this.state.type === "article"
          ? JSON.stringify(
              convertToRaw(this.state.articleEditorState.getCurrentContent())
            )
          : "",
      dataURLs: this.state.dataURLs,
    };
    this.props.editPostDetails(postDetails);
    this.handleClose();
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      data,
      UI: { loading },
    } = this.props;

    let tags = !loading ? data.tags : [{ name: "None" }];

    let contentState = "";
    let editorState = "";

    let dialogContent = (
      <div>
        <DialogTitle>Edit Post Details</DialogTitle>
        <DialogContent className={classes.editPostDialog}>
          {loading ? (
            <CircularProgress size={100} thickness={2} />
          ) : (
            <form className={classes.editPostForm}>
              {this.state.type === "article" ? (
                <Editor
                  toolbarClassName={classes.textEditorToolbar}
                  wrapperClassName={classes.textEditorWrapper}
                  editorClassName={classes.textEditor}
                  onEditorStateChange={this.onEditorStateChange}
                  editorState={this.state.articleEditorState}
                />
              ) : null}

              <TextField
                id="content"
                name="content"
                type="text"
                label="Content"
                multiline
                rows="2"
                variant="outlined"
                placeholder="Post content..."
                error={errors.content ? true : false}
                helperText={errors.content}
                className={classes.textFields}
                value={this.state.content}
                onChange={this.handleChange}
                fullWidth
              />

              {post_types.includes(this.state.type) ? (
                <Fragment>
                  <TextField
                    id="location"
                    name="location"
                    type="text"
                    label="Location"
                    rows="1"
                    placeholder="Location"
                    error={errors.location ? true : false}
                    helperText={errors.location}
                    className={classes.textFields}
                    value={this.state.location}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                  <div className={classes.latlon}>
                    <TextField
                      id="lat"
                      name="lat"
                      type="text"
                      label="Lat"
                      rows="1"
                      placeholder="Latitude"
                      error={errors.location ? true : false}
                      helperText={errors.location}
                      className={classes.textFields}
                      value={this.state.lat}
                      onChange={this.handleChange}
                      variant="outlined"
                    />

                    <TextField
                      id="lon"
                      name="lon"
                      type="text"
                      label="Lon"
                      rows="1"
                      placeholder="Longitude"
                      error={errors.location ? true : false}
                      helperText={errors.location}
                      className={classes.textFields}
                      value={this.state.lon}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </div>
                  <div className="tag-control">
                    <Box>
                      <FormControl>
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{ minWidth: "500px" }}
                        >
                          Tag
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="outlined"
                          label="Tag"
                          value={this.state.tag}
                          onChange={this.setTag}
                        >
                          <MenuItem button key="empty" value="">
                            <i>None</i>
                          </MenuItem>
                          {tags.map((tag) => (
                            <MenuItem button key={tag.name} value={tag.name}>
                              {tag.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </Fragment>
              ) : null}

              {this.state.type === "video" ? (
                <Fragment>
                  <div>
                    <TextField
                      id="videoURL"
                      name="videoURL"
                      type="text"
                      label="Video URL"
                      variant="outlined"
                      error={errors.videoURL ? true : false}
                      helperText={errors.content}
                      className={classes.textFields}
                      value={this.state.videoURL}
                      onChange={this.handleChange}
                      fullWidth
                    />
                  </div>
                </Fragment>
              ) : null}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </div>
    );

    return (
      <Fragment>
        <Button
          onClick={this.handleOpen}
          fullWidth
          className={classes.adminPostButton}
        >
          Edit Post
        </Button>

        <MediaQuery query="(min-width: 1200px)">
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth={this.state.maxWidth}
          >
            {dialogContent}
          </Dialog>
        </MediaQuery>

        <MediaQuery query="(max-width: 1200px)">
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullScreen
            maxWidth={this.state.maxWidth}
          >
            {dialogContent}
          </Dialog>
        </MediaQuery>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.data.post,
  data: state.data,
  UI: state.UI,
});

const mapActionsToProps = { editPostDetails, getPost };

EditPostDetails.propTypes = {
  getPost: PropTypes.func.isRequired,
  editPostDetails: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  //   clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(EditPostDetails));
