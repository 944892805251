import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const UnauthRoute = ({ component: Component, authenticated, currentUser, ...rest }) => (
  

  <Route
    {...rest}
    render={(props) =>
      (authenticated === false && authenticated !== null)  ? <Redirect to="/welcome" /> : <Component {...props} />
    }
  />
  
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  currentUser: state.user.currentUser
});

UnauthRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(UnauthRoute);