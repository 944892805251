import React from 'react';

import { Icon } from '@mui/material';



export default ({ component, className, fontSize, color }) => (
    <Icon className={className} fontSize={fontSize} color={color} component={component}/>
      
);


