import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getFilteredNews, setFilterOptions } from '../redux/actions/dataActions';
import { minDate, mobileMediaQuery, news_types_array, types_icon } from '../util/Global';
import Icon from '../util/Icons';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


// Icons

import { connect } from 'react-redux';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = (theme) => ({
    ...theme.spreadThis,
    filterMenu: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        paddingLeft: "25px",
        [`@media ${mobileMediaQuery}`]: {
            paddingLeft: "0px"
        },
    },
    filterMenuCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: "15px",
        border:"2px #f4f6f6 solid",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        margin: 0,
        // padding: 10,
        "&:last-child": {
            paddingBottom: 0
        },
        [`@media ${mobileMediaQuery}`]: { 
            boxShadow:"none",
          },
    },
    filterCardContent: {
        padding:"8px 14px 16px 14px"
    },
    tag: {
        margin: "6px 3px 0px 3px",
        fontWeight: "bold"
    },
    menuLabel: {
        display: "flex",
        alignItems: "center",
        color: "#3a4387",
        gap: "6px",
        padding: "12px 0px 0px 14px"
    },

    navMenuSeparator: {
        border: 'none',
        // borderBottom:"1px solid #dfe4ea",
        margin: "0px"
    },

});


class NewsFilterMenu extends Component {

    state = {
        hasFilter: false
    }



    render() {

        const { classes, data, UI: { loading } } = this.props;


        let datePicker = !loading ? (

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Month, Year"
                    value={data.filterOptions.FILTER_dateStart === "" ? null : data.filterOptions.FILTER_dateStart}
                    color="info"
                    variant="inline"
                    minDate={minDate}
                    maxDate={new Date()}
                    views={['month', 'year']}
                    onChange={(newValue) => {
                        handleSelectDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>

        )

            : (<div></div>);

        let types = !loading ? (

            news_types_array.map((type) => {
                return (
                    <Chip
                        key={type}
                        label={capitalizeFirstLetter(type)}
                        icon={<Icon fontSize="small" color="secondary" component={types_icon[type]} />}
                        onClick={() => handleSelectType(type)}
                        variant={data.filterOptions.FILTER_type === type ? "default" : "outlined"}
                        //    onChange={() => handleFilterChange()}
                        color={data.filterOptions.FILTER_type === type ? "primary" : "secondary"}
                        size="medium"
                        className={classes.tag}
                        sx={{ padding: "0px 5px" }} />

                );
            })
        )


            : (<div></div>);


        const handleSelectType = (type) => {
            if (data.filterOptions.FILTER_type !== type) {
                this.props.setFilterOptions({
                    ...data.filterOptions,
                    FILTER_type: type
                });
                handleFilterChange({
                    FILTER_type: type
                })
            }
            else {
                this.props.setFilterOptions({
                    ...data.filterOptions,
                    FILTER_type: ""
                });
                handleFilterChange({
                    FILTER_type: ""
                })
            }
        };

        const handleSelectDate = (date) => {
            if (date !== null) {
                const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
                const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
                this.props.setFilterOptions({
                    ...data.filterOptions,
                    FILTER_dateStart: startDate,
                    FILTER_dateEnd: endDate
                });
                handleFilterChange({
                    FILTER_dateStart: startDate,
                    FILTER_dateEnd: endDate
                })
            }
            else {
                this.props.setFilterOptions({
                    ...data.filterOptions,
                    FILTER_dateStart: "",
                    FILTER_dateEnd: ""
                });
                handleFilterChange({
                    FILTER_dateStart: "",
                    FILTER_dateEnd: ""
                })
            }
        };


        const handleFilterChange = (newFilterOptions) => {
            this.props.getFilteredNews({
                ...data.filterOptions,
                ...newFilterOptions
            });
            hasFilter({
                ...data.filterOptions,
                ...newFilterOptions
            });
        };

        const hasFilter = (newOptions) => {
            if (newOptions.FILTER_type !== "" ||
                newOptions.FILTER_dateStart !== "" ||
                newOptions.FILTER_dateEnd !== "") {
                this.setState({ hasFilter: true })
            }
            else this.setState({ hasFilter: false })

        }

        const clearFilter = () => {
            this.props.setFilterOptions({
                FILTER_type: "",
                FILTER_dateStart: "",
                FILTER_dateEnd: ""
            });
            handleFilterChange({
                FILTER_type: "",
                FILTER_dateStart: "",
                FILTER_dateEnd: ""
            })

        };

        return (
            <div className={classes.filterMenu}>

<div>
                    
                    <Card className={classes.filterMenuCard} elevation={0}>
                        <div className={classes.menuLabel}>
                    <Typography className={classes.wait} color="info" variant="subtitle" style={{ fontWeight: 600 }}>Type</Typography>
                    </div>
                    <div className={classes.filterCardContent}>{types}</div>
                    </Card>
                </div>

                <hr className={classes.navMenuSeparator} />

                <div>
                    
                    <Card className={classes.filterMenuCard} elevation={0}>
                        <div className={classes.menuLabel}>
                    <Typography className={classes.wait} color="info" variant="subtitle" style={{ fontWeight: 600 }}>Date</Typography>
                    </div>
                    <div className={classes.filterCardContent}>{datePicker}</div>
                    </Card>
                </div>

                <div>
                    <div className={classes.menuLabel}>
                        {/* <HiHashtag color="secondary" className={classes.navMenuItemIcon}/> */}

                    </div>


                    {this.state.hasFilter &&
                        <Button variant="text" color="secondary" style={{ textAlign: "center" }} onClick={clearFilter}>
                            Clear
                        </Button>
                    }

                </div>

            </div>
        )
    }
}

NewsFilterMenu.propTypes = {
    getFilteredNews: PropTypes.func.isRequired,
    setFilterOptions: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    data: state.data,
    UI: state.UI
});

export default connect(mapStateToProps, { getFilteredNews, setFilterOptions })(withStyles(styles)(NewsFilterMenu));
