import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
// MUI Stuff
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Redux stuff
import { connect } from 'react-redux';
import { submitComment } from '../redux/actions/dataActions';
import { isEmpty } from '../util/validation';

const styles = (theme) => ({
  ...theme.spreadThis,
  commentTextField:{
    width: "90%",
    height: "auto",
    padding:"0px 5%"
  },
  commentForm:{
    position:"relative"
    // display:"flex",
    // flexDirection:"row",
    // alignItems:"center"
  },
  sendButton:{
    position: "absolute",
    bottom: "10px",
    right: "calc(5% + 2px)",
    // [`@media ${mobileMediaQuery}`]: {
    //   right: "calc(10% + 2px)",
    // }
  }
});

class CommentForm extends Component {
  state = {
    body: '',
    errors: {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ body: '' });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.submitComment(this.props.postId, { content: this.state.body });
    this.setState({body:""});
  };

  render() {
    const { classes, muted, authenticated } = this.props;
    const errors = this.state.errors;

    const commentFormMarkup = authenticated && !muted ? (
      <div >
        <form onSubmit={this.handleSubmit} className={classes.commentForm}>
          <TextField
            name="body"
            type="text"
            placeholder="Write a comment..."
            error={errors.comment ? true : false}
            helperText={errors.comment}
            value={this.state.body}
            color="secondary"
            variant="outlined"
            multiline
            fullWidth
            inputProps={{ maxLength: 250 }}
            onChange={this.handleChange}
            className={classes.commentTextField}
          />
          <Button
            type="submit"
            variant="text"
            color="secondary"
            disabled={isEmpty(this.state.body)}
            className={classes.sendButton}
          >
            {/* <SendIcon/> */}
            <strong>Send</strong>
          </Button>
        </form>
        <hr className={classes.invisibleSeparator} />
        </div>
    ) : (<p className='center' style={{padding:"0px",height: "20px",color: "#71829a",fontWeight: "bold"}}>You are muted.</p>);
    return commentFormMarkup;
  }
}

CommentForm.propTypes = {
  submitComment: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  authenticated: state.user.authenticated,
  muted: state.user.credentials.muted
});

export default connect(
  mapStateToProps,
  { submitComment }
)(withStyles(styles)(CommentForm));