import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


import { icon_set } from '../util/Global';
import Icon from '../util/Icons';



// MUI Stuff
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
// Icons
// import AddIcon from '@mui/icons-material/Add';

const styles = (theme) => ({
  ...theme.spreadThis,
  navBarWelcomeButton:{
    fontWeight:"600"
  }
})

class Navbar extends Component {
  render() {
    const { classes, user: {authenticated, admin} } = this.props;
    // let color = authenticated ? "white" : "white";
    return (
      <AppBar position="fixed" elevation={0} sx={{backgroundColor:"white"}}>
        <Toolbar className="nav-container">
        <div className="nav-left">
          {authenticated ? (
          null
          ) : 
          (
          <div>        
          <Button className={classes.navBarWelcomeButton} color="secondary" component={Link} to="/welcome">Home</Button>
        <Button className={classes.navBarWelcomeButton} color="secondary" component={Link} to="/login">Login</Button>
        <Button className={classes.navBarWelcomeButton} color="secondary" component={Link} to="/join">Join</Button>
          </div>
          )}
        
        </div>
        <div className="nav-middle">
        {authenticated ? (
            <Fragment>
          <Button color="secondary" component={Link} to="/">
            <Icon fontSize="medium" color="secondary" component={icon_set["homeOutlined"]}/>
          </Button>
          <Button color="secondary" component={Link} to="/news">
            <Icon fontSize="medium" color="secondary" component={icon_set["newsOutlined"]}/>
          </Button>
          <Button color="secondary" component={Link} to="/chat">
            <Icon fontSize="medium" color="secondary" component={icon_set["chatOutlined"]}/>
          </Button>
          <Button color="secondary" component={Link} to="/profile">
            <Icon fontSize="medium" color="secondary" component={icon_set["profileOutlined"]}/>
          </Button>          
          <Button color="secondary" component={Link} to="/manage">
            <Icon fontSize="medium" color="secondary" component={icon_set["manageOutlined"]}/>
          </Button>
          </Fragment>
          ) : 
          (null)}
          
        </div>

        <div className="nav-right">
        
        </div>
        </Toolbar>
        
      </AppBar>
    );
  }
}


Navbar.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(Navbar));
