import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { createStripeCheckoutSession } from '../redux/actions/userActions';

// MUI Stuff

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoyaltyIcon from '@mui/icons-material/Loyalty';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class CreateStripeSession extends Component {
  state = {
  };

  handleOpen = (username,email,stripeCustomerId) => {
    let formData = {
      productId:"prod_LPYSGiwvZ4n21M",
      priceId:"price_1L0VAEHfLytCAA9zn8oVEq6H",
      mode:"subscription",
      username:username,
      email:email,
      stripeCustomerId:stripeCustomerId
    };
    this.props.createStripeCheckoutSession(formData)
  };


  render() {
    const { errors } = this.state;
    const { classes, username, subscriptionTier , email, stripeCustomerId, data ,UI: { loading } } = this.props;

    const isSubscribed = subscriptionTier > 1 ? "Active" : "Inactive";
    return (
      <Fragment>
           <ListItemButton
                onClick={() => {this.handleOpen(username,email,stripeCustomerId)}}
                >
                  <ListItemIcon>
                   <LoyaltyIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Activate Subscription"
                    secondary={"Subscription: " + isSubscribed}
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>
        
      </Fragment>
      );
  }
}

CreateStripeSession.propTypes = {
  createStripeCheckoutSession: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { createStripeCheckoutSession, clearErrors }
)(withStyles(styles)(CreateStripeSession));