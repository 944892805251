import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { changeUserUsername } from '../redux/actions/userActions';

// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class ChangeUsername extends Component {
  state = {
    open: false,
    errors: {},
    newUsername:''
  };

  handleOpen = () => {
    this.props.handler()
  };
  handleClose = () => {
    this.props.handler()
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    this.props.changeUserUsername({oldUsername:this.props.currentUsername,newUsername:this.state.newUsername});
  };


  render() {
    const { classes, currentUsername ,UI: { loading, errors } } = this.props;

    return (
      <Fragment>
           
              <Card hidden={!this.props.open} onClose={this.handleClose} elevation={0}>
                <Button tip="Close" onClick={this.handleClose}>
                <ArrowBackIcon color="info" />
                  </Button>
                  <DialogTitle>Change Username</DialogTitle>
                  <DialogContent>
                    <p>Your current username is {currentUsername}. What would you like to change it to?</p>
                

                    {/* <form> */}
                      <TextField
                      id="newUsername"
                      name="newUsername"
                      type="text"
                      value={this.state.newUsername}
                      label="New Username"
                      variant="outlined"
                      placeholder="New Username"
                      error={errors.newUsername ? true : false}
                      helperText={errors.newUsername}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                    
                    <Button type="button" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>
                    {/* </form> */}
                  </DialogContent>
              </Card>
      </Fragment>
      );
  }
}

ChangeUsername.propTypes = {
  changeUserUsername: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { changeUserUsername, clearErrors }
)(withStyles(styles)(ChangeUsername));