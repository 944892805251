import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import withStyles from '@mui/styles/withStyles';
import { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { desktopMediaQuery, mediaURLBuilder, mobileMediaQuery } from '../util/Global';

const styles = (theme) => ({
  ...theme.spreadThis,
  imageList: {
    overflow: "hidden"
  },
  imageListItem:{
    objectFit:"none",
  objectPosition: "center"
    
  }
});

export class ImagesPostGrid extends Component{
  render() {

const { classes, images } = this.props
    const urls = Array.from(images);
    let itemData = []
    urls.forEach(element => {
      itemData.push({
          img: mediaURLBuilder(element),
          title: 'Image'
      })
    })
    let numColumnsDesktop = 3;
    let numColumnsMobile = 2;
    if (urls.length === 1){
      numColumnsDesktop = 1
      numColumnsMobile = 1
    }
    else if (urls.length === 2){
      numColumnsDesktop = 2
    };




  return (
    <Fragment>
    <MediaQuery query={desktopMediaQuery}>
      <ImageList variant="standard" cols={numColumnsDesktop} rowHeight={300} className={classes.imageList}>
        {itemData.slice(0,Math.min(6,itemData.length)).map((item) => (
          <ImageListItem key={item.img} className={classes.imageListItem}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              className={classes.imageListItem}
            />
          </ImageListItem>
        ))}
      </ImageList>
      </MediaQuery>

       <MediaQuery query={mobileMediaQuery}>
       <ImageList variant="standard" cols={numColumnsMobile}  rowHeight={175} className={classes.imageList}>
         {itemData.slice(0,Math.min(4,itemData.length)).map((item) => (
           <ImageListItem key={item.img} className={classes.imageListItem}>
             <img
               src={`${item.img}`}
               srcSet={`${item.img}`}
               alt={item.title}
               loading="lazy"
               className={classes.imageListItem}
             />
           </ImageListItem>
         ))}
       </ImageList>
       </MediaQuery>
       </Fragment>
  );
        }
}

export default withStyles(styles)(ImagesPostGrid);
