import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { createStripeBillingPortalSession } from '../redux/actions/userActions';

// MUI Stuff

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class ManageStripeDetails extends Component {
  state = {
  };

  handleOpen = (stripeCustomerId) => {
    let formData = {
      stripeCustomerId:stripeCustomerId
    };
    this.props.createStripeBillingPortalSession(formData)
  };


  render() {
    const { errors } = this.state;
    const { classes, stripeCustomerId ,data ,UI: { loading } } = this.props;

    // const isSubscribed = subscriptionTier > 1 ? "Active" : "Inactive";
    return (
      <Fragment>
           <ListItemButton
                onClick={() => {this.handleOpen(stripeCustomerId)}}
                >
                  <ListItemIcon>
                   <SettingsIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Edit Payment Details and Billing Information"
                    secondary="Also, cancel your Subscription and View Payment History."
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>
        
      </Fragment>
      );
  }
}

ManageStripeDetails.propTypes = {
  createStripeBillingPortalSession: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { createStripeBillingPortalSession, clearErrors }
)(withStyles(styles)(ManageStripeDetails));