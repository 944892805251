import { mergeClasses } from '@mui/styles';
import React, { Component, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../util/Icons'
import {icon_set, mobileMediaQuery} from '../util/Global'


import { connect } from 'react-redux';

// MUI Stuff
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';



const styles = (theme) => ({
  ...theme.spreadThis,
  navMenuCard: {
    
    position: 'fixed',
    width:'20%',
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      width: 'auto'
    },
    height:"100vh",
    background:"transparent",

    margin:0,
padding:0,
paddingBottom: 0,
display:"flex",
flexDirection:"column",
justifyContent:"space-between",

"&:last-child": {
  paddingBottom: 0
}
  },
  menu:{
    display:"flex",
    flexDirection:"column",
    gap:"12px",
    
  },
  homeBanner: {
margin:"0px",
height:"50px",
boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
borderRadius:"50%"
  },
  homeIconContainer: {
    // textAlign:"center",
    // display:"flex",
    marginLeft:"14px",
    padding:0,
    marginTop:-10,
    // marginRight:"auto",
    // marginBottom:"5px",
  },
  navMenuItemContainer:{
    marginRight:12,
    borderRadius:"150px",
    
    [`@media ${mobileMediaQuery}`]: {
    padding: "5px 5px",
    }
  },
  navMenuItem: {
    textAlign: "center",
    // paddingTop:"5px",
    TextDecoder: "none",
    fontSize:"18px",
    // marginLeft:"28px"
  },
  navMenuItemIcon: {
    marginLeft:"20px",
    marginRight:"28px",
    fontSize:"24px",
    fontWeight:"bold"
  },
  navMenuSeparator: {
    border:'none',
    width:'100%',
    borderBottom:"0px solid #dfe4ea",
    margin:"0px"
  },
  footer:{
    margin:"30px",
    color:"#938d94",
  fontSize:"12px",
  '& :visited': {
    color:"#938d94"
  }
  }
});

export class NavComponents extends Component {
  
  render() {
    const { classes, user: { admin, hasNews } } = this.props;
    
    return (
      <Fragment>
        
    
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/"}>
      {window.location.pathname === '/' ? (
        // <HomeIcon color="info" className={classes.navMenuItemIcon}/>
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="secondary" component={icon_set["homeFilled"]}/>
      ) : 
      (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["homeOutlined"]}/>
      )}
    <Typography className={classes.navMenuItem} color={window.location.pathname === '/' ? "secondary" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/' ?  600 : 500 }}>Home</Typography>
    </MenuItem>
    
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/news"}>
    {window.location.pathname === '/news' ? (
      
       <Icon className={classes.navMenuItemIcon} fontSize="medium" color="secondary" component={icon_set["newsFilled"]}/>
      ) : 
      (
        <Badge color="secondary" badgeContent="!" overlap="circular" invisible={!hasNews} anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["newsOutlined"]}/>
        </Badge> 
      )}   
      
      <Typography className={classes.navMenuItem} color={window.location.pathname === '/news' ? "secondary" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/news' ?  600 : 500 }}>Community</Typography>
      
    </MenuItem>
    
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/chat"}>
    {window.location.pathname === '/chat' ? (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="secondary" component={icon_set["chatFilled"]}/>
      ) : 
      (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["chatOutlined"]}/>
      )}      
      <Typography className={classes.navMenuItem} color={window.location.pathname === '/chat' ? "secondary" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/chat' ?  600 : 500 }}>Chat</Typography>
    </MenuItem>
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/profile"}>
    {window.location.pathname === '/profile' ? (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="secondary" component={icon_set["profileFilled"]}/>
      ) : 
      (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["profileOutlined"]}/>
      )}  
      <Typography className={classes.navMenuItem} color={window.location.pathname === '/profile' ? "secondary" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/profile' ?  600 : 500 }}>Profile</Typography>
    </MenuItem>
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/manage"}>
    {window.location.pathname === '/manage' ? (
         <Icon className={classes.navMenuItemIcon} fontSize="medium" color="secondary" component={icon_set["manageFilled"]}/>
      ) : 
      (
        <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["manageOutlined"]}/>
      )}  
    <Typography className={classes.navMenuItem} color={window.location.pathname === '/manage' ? "secondary" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/manage' ?  600 : 500 }}>Manage</Typography>
    </MenuItem>

    {admin ? (
    
    <MenuItem className={classes.navMenuItemContainer} component={Link} to={"/admin"}>
    {window.location.pathname === '/admin' ? (
       <Icon className={classes.navMenuItemIcon} fontSize="medium" color="info" component={icon_set["adminFilled"]}/>
       ) : 
       (
         <Icon className={classes.navMenuItemIcon} fontSize="small" color="info" component={icon_set["adminOutlined"]}/>
      )}  
     <Typography className={classes.navMenuItem} color={window.location.pathname === '/admin' ? "info" : "info"} variant="body1"  style={{ fontWeight: window.location.pathname === '/admin' ?  600 : 500 }}>Admin</Typography>
    </MenuItem> 
          ) : null}
    
    
    </Fragment>
      );
  }
}

NavComponents.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

// export default withStyles(styles)(NavComponents);


const mapStateToProps = state => ({
  user: state.user
})

export default connect(
  mapStateToProps
)(withStyles(styles)(NavComponents));