import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors, createTag } from '../redux/actions/dataActions';
import { isEmpty } from '../util/validation';

import { mobileMediaQuery } from '../util/Global';

// MUI Stuff
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MyButton from '../util/MyButton';


const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    
  },
  progressSpinner: {
    position: 'absolute'
  },
  createTagForm:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    gap:10,
    [`@media ${mobileMediaQuery}`]: {
      flexDirection: "column",
    },
  },
  tag:{
    margin:"6px 3px 0px 3px",
    fontWeight:"bold"
},
postDetailsBanner: {
  display: "flex",
  justifyContent: "space-between",
  padding: "5px 20px",
  alignItems: "center",
  [`@media ${mobileMediaQuery}`]: {
    padding: "5px",
  },
},
})


class CreateTag extends Component {
  state = {
    open: false,
    errors: {},
    name:''
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {} });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    this.props.createTag({ name: this.state.name });
    this.setState({ open: false });
  };

  render() {
    const { errors } = this.state;
    const { classes, data ,UI: { loading } } = this.props;

    return (
      <Fragment>
        <Chip
            variant=  "outlined"
            onClick={this.handleOpen}
            label="Create"
            icon={<AddIcon/>}
            color= "secondary"
            size="medium"
            className={classes.tag}
          /> 
        {/* <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={this.handleOpen}
              disableElevation
            >
              <AddIcon style={{ marginRight: "10px" }}/>
              Create Tag
              </Button> */}
              <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="sm">
              <div className={classes.postDetailsBanner}>
          <DialogTitle>
            Create A New Tag
          </DialogTitle>
          <MyButton
            tip="Close"
            onClick={this.handleClose}
            tipClassName={classes.closeButton}
          >
            <CloseIcon />
          </MyButton>
        </div>
                  <DialogContent className={classes.createTagForm}>

                      <TextField
                      id="name"
                      name="name"
                      type="text"
                      label="Tag Name"
                      variant="outlined"
                      placeholder="Tag name..."
                      error={errors.name ? true : false}
                      helperText={errors.name}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      />

                    
                    <Button type="button" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading || isEmpty(this.state.name)}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>
                  </DialogContent>
              </Dialog>
      </Fragment>
      );
  }
}

CreateTag.propTypes = {
  createTag: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { createTag, clearErrors }
)(withStyles(styles)(CreateTag));