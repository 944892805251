import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';


import HomeFilterMenu from './HomeFilterMenu';
import NewsFilterMenu from './NewsFilterMenu';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


const styles = (theme) => ({
  ...theme.spreadThis,
   
  });


export class FilterDrawer extends Component {
 
  state = {
    open:false
  }

  closeDrawer = () => (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: false });
    // event.stopImmediatePropagation()
  };

  openDrawer = () => (event) => {
    this.setState({ open: true });
  };

  render(){
    const { classes, user: { admin } } = this.props;

  return (
    
    <div>
          <Button onClick={this.openDrawer()} disabled={window.location.pathname !== '/' && window.location.pathname !== '/news'}>
            <MoreHorizIcon color="info" fontSize="large"/>
            </Button>
          <SwipeableDrawer
            anchor="right"
            open={this.state.open}
            onClose={this.closeDrawer()}
            onOpen={this.openDrawer()}
          >
            
            <Box
        sx={{ width: 350 }}
        role="presentation"
       
      >
            
            <Fragment>
        
        
      <Card className={classes.navMenuCard} elevation={0} >
        
      <CardContent>
        
      {window.location.pathname === '/' && 
    <HomeFilterMenu/>}

    {window.location.pathname === '/news' && 
    <NewsFilterMenu/>}
    
    </CardContent>
    
    
    </Card>

    
    </Fragment>

            </Box>
          </SwipeableDrawer>
    </div>
  );
  }
}   


FilterDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}



const mapStateToProps = state => ({
  user: state.user
})

export default connect(
  mapStateToProps
)(withStyles(styles)(FilterDrawer));