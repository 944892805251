import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export default ({ children, onClick, tip, btnClassName, tipClassName,href }) => (
  <Tooltip title={tip} className={tipClassName} placement="bottom">
    <InfoIcon onClick={onClick} className={btnClassName} href={href} size="large" 
    style={{color:"#938d94"}}/>
  </Tooltip>
);