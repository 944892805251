import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors } from '../redux/actions/dataActions';
import { deleteAccount } from '../redux/actions/userActions';

// MUI Stuff
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
})


class DeleteAccount extends Component {
  state = {
    open: false,
    errors: {},
    password:'',
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    let userData = {
    password:this.state.password,
    username:this.props.username,
    }
    this.props.deleteAccount(userData);
    this.setState({ open: false });
  };


  render() {
    const { errors } = this.state;
    const { classes, data ,UI: { loading } } = this.props;

    return (
      <Fragment>
           <ListItemButton
                onClick={this.handleOpen}
                >
                   <ListItemIcon>
                   <DeleteIcon/>
                  </ListItemIcon>

                  <ListItemText
                    primary="Delete Account"
                    secondary="This cannot be undone."
                  />
                    <ChevronRightIcon />
                    
                </ListItemButton>
        
              <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="sm">
                <Button tip="Close" onClick={this.handleClose}>
                  <CloseIcon />
                  </Button>
                  <DialogTitle>Delete Account</DialogTitle>
                  <DialogContent>
                    <p>This action cannot be undone. Please enter your current password to confirm. </p>
                

                    {/* <form> */}
                     
                    <TextField
                      id="password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      label="Password"
                      variant="outlined"
                      placeholder="Password"
                      error={errors.password ? true : false}
                      helperText={errors.password}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      fullWidth
                      />

                 
                    
                    <Button type="button" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>
                    {/* </form> */}
                  </DialogContent>
              </Dialog>
      </Fragment>
      );
  }
}

DeleteAccount.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { deleteAccount, clearErrors }
)(withStyles(styles)(DeleteAccount));