// import GG from '../images/WelcomeBackground.jpg'
import {desktopMediaQuery,mobileMediaQuery} from './Global'
 
export default {
  palette: {
    primary: {
      light: "#e64f51",
      main: "#e64f51",
      dark: "#e64f51",
      contrastText: "#fff",
    },
    secondary: {
      light: "#3a4387",
      main: "#3a4387",
      dark: "#3a4387",
      contrastText: "#fff",
    },
    info: {
      main: "#1c1d1d",
      text: "#1c1d1d",
    },
  },
  text: { primary: { 500: "#e64f51" } },

  spreadThis: {
    typography: {
      useNextVariants: true,
      textTransform: "none",
      fontFamily: ["Roboto"],
    },
    form: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      gap:10
    
    },
    icon: {
      height: "150px",
      margin: "20px auto 10px auto",
    },
    welcomeIcon: {
      height: "50px",
    },
    mediaIcon: {
      height: "100%",
    },
    pageTitle: {
      fontSize: "40px",
      fontWeight: "30",
      margin: "10px auto 20px auto",
    },
    textField: {
      margin: "5px auto 5px auto",
    },
    textFields: {
      margin: "5px auto 5px auto",
      width:"100%",
    },
    button: {
      margin: "20px auto 20px auto",
    },
    buttonSecondary: {
      margin: "0px",
    },
    welcomePagesContainer:{
      padding:30,
      textAlign: "center",
    },
    welcomeButtons: {
      borderRadius: "12px",
      width: "150px",
      fontWeight: "bold",
      color:"white"
    },
    customError: {
      color: "#e64f51",
      fontSize: "0.8rem",
    },
    progress: {
      marginLeft: "20px",
    },
    spinnerDiv: {
      textAlign: "center",
      margin: "50px 0px",
    },
    visibleSeparator: {
      border: "none",
      width: "100%",
      borderBottom: "1px solid #dfe4ea",
      marginBottom: "20px",
    },
    invisibleSeparator: {
      border: "none",
      margin: 4,
    },
    navMenuContainer: {
      display: 'flex', 
      position: 'fixed', 
      width: '23.3%'
    },
    mainPageContainer:{
      marginRight: "auto", 
      marginLeft: "auto", 
      display: "flex",
    },
    mainFeedGridContainer:{
      margin: "10px", 
      overflow: "auto",
      background:"#fdfdfd",
      [`@media ${mobileMediaQuery}`]: { 
        display: "flex",
        justifyContent: "center"
      },
      },

    mainFeedItemContainer:{
      overflow: "auto",
      [`@media ${mobileMediaQuery}`]: { 
        marginTop:"75px"
      },
    },
    filterMenuContainer:{
      display: 'flex', 
      justifyContent: 'flex-end', 
      position: 'fixed', 
      width: '23%'
    },
    
    fab: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    paper: {
      padding: 40,
      margin: 20,
    },

    adminPostButton: {
      backgroundColor: "#f4f6f6",
      color: "#9A9A9A",
      borderRadius: "500px",
      opacity: "0.8",
      "&:hover": {
        opacity: "1",
        color: "#1c1d1d",
        backgroundColor: "#f4f6f6",
      },
    },
    
    buttons: {
      textAlign: "center",
      "& a": {
        margin: "20px 10px",
      },
    },
    card: {
      display: "flex",
      flexDirection: "row",
    },

    profileImageWrapper:{
      textAlign: "center",
      position: "relative",
    },
    profileImage:{
      width: 200,
      height: 200,
      objectFit: "cover",
      maxWidth: "100%",
      borderRadius: "50%",
      background:"#fff",
    borderRadius: "500px",
    border:"2px #f4f6f6 solid",
      [`@media ${mobileMediaQuery}`]: { 
        width: "150px",
      height: "150px",
      },
    },

    
  },
};
