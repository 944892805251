import withStyles from '@mui/styles/withStyles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { isEmpty, mediaURLBuilder, types_icon } from '../util/Global';
import Icon from '../util/Icons';
import LikeButton from './LikeButton';
import Poll from './Poll';
import PostDialog from './PostDialog';

// Custom Stuff
import DeletePost from './DeletePost';
import EditPostDetails from './EditPostDetails';
import ImagesPostGrid from './ImagesPostGrid';
// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

// Icons
import ArticleIcon from '@mui/icons-material/Article';
import ChatIcon from '@mui/icons-material/Chat';
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleOutline';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';
import TagIcon from '@mui/icons-material/Tag';

import { connect } from 'react-redux';
import MyButton from '../util/MyButton';

import { setPostInitial } from '../redux/actions/dataActions';

// Icons
// import ImageIcon from '@mui/icons-material/Image';


const styles = (theme) => ({
  ...theme.spreadThis,
  card: {
    position: 'relative',
    display: 'flex',
    marginBottom: 30,
    // marginTop: 10,
    // boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    // boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;",
    border:"2px #f4f6f6 solid",
    backgroundColor:"#fff",
    borderRadius:"15px"
  },
  cardContent: {
    width:'100%',
    display: 'flex',
    flexDirection:'column',
    gap:15
  },
  mediaIcon: {
    height: "100%"
  },
  content: {
    padding: 25,
    objectFit: 'cover'
  },
  videoThumbnailContainer:{
    // maxWidth:"650px",
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"relative"
  },
  videoThumbnail:{
    width:"100%",
    backdropFilter: "blur(6px)",
    // zIndex:"1"
  },
  videoThumbnailPlayIcon:{
    position: "absolute",
    fontSize:"100px",
    color:"#dfe4ea",
    backgroundColor:"#fff",
    borderRadius:"50%",
    top : "50%",
    left : "50%",
    transform: "translate(-50%, -50%)",
    zIndex:"2",
    "&:hover":{
      fontSize:"120px",
    }
  },
  articleThumbnailPlayIcon:{
    position: "absolute",
    fontSize:"90px",
    color:"#dfe4ea",
    backgroundColor:"#fff",
    borderRadius:"50%",
    top : "50%",
    left : "50%",
    padding:"10px",
    transform: "translate(-50%, -50%)",
    zIndex:"2",
    "&:hover":{
      fontSize:"110px",
    }
  },
  postInteractionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:20,
    justifyContent: 'space-end',
    
    ['@media (max-width:1200px)']: { // eslint-disable-line no-useless-computed-key
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap:0,
    }
  },
  adminPostInteraction:{
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    gap:5
  },
  postInteraction: {
    display: 'flex',
    alignItems: 'center',
    gap:5
  },
  imageDateLocationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap:10
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap:20,
    justifyContent:'space-between'
  },
  tag:{
    fontSize:10,
    textAlign:"center",
    color:"white",
    padding:"2px 5px",
    borderRadius:"20px",
    backgroundColor:"#71829a",
    height:"13px"
  },
  commentInteraction:{
    '&:hover':{
      cursor:"pointer"
    }
  }
});

export class Post extends Component {
  state = {
    openDialog: false
  }


  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
  }

  handler() {
    if (!this.state.openDialog ){
    this.props.setPostInitial(this.props.post.postId)
  }
    this.setState({
       openDialog: !this.state.openDialog 
    })
  }

  // handleOpen = () => {
  //   // this.setState({ openDialog: true });
  // };

  

  render() {
    dayjs.extend(relativeTime);
    const { classes, post : { content, createdAt, dataURLs, postId, pollOptions, type, tag, location, likeCount, commentCount}, data: {filterOptions: { FILTER_tag, FILTER_type} }, user: { admin, authenticated }} = this.props
    let media;
    let mediaIcon = <Icon className={classes.mediaIcon} fontSize="large" color={FILTER_type === type ? "primary" : "secondary"} component={types_icon[type]}/>
    if (type === "photo" && dataURLs.length > 0) {
      media = <Button className={classes.videoThumbnailContainer} color="secondary" onClick={this.handler}>
        <ImagesPostGrid images={dataURLs} />
        </Button>
      
    } 
    else if (type === "video" && dataURLs.length > 0) {
      media = 
      <Button className={classes.videoThumbnailContainer} color="secondary" onClick={this.handler}>
        <PlayIcon className={classes.videoThumbnailPlayIcon}/>
      <img src={mediaURLBuilder(dataURLs[0].thumbnail)} className={classes.videoThumbnail} style={{maxHeight:"400px",width:"auto"}}/>
      </Button>
      
    } 
    else if (type === "article") {
      media = 
      <Button className={classes.videoThumbnailContainer} color="secondary" onClick={this.handler}>
        <ArticleIcon className={classes.articleThumbnailPlayIcon}/>
      <img src={mediaURLBuilder(dataURLs[0].thumbnail)} className={classes.videoThumbnail}/>
      </Button>
    } 
    else if (type === "text") {
      
    } 

    else if (type === "poll") {
      media = <Poll key={postId} postId={postId} pollOptions={pollOptions}/>
    } 

    

    const chatIcon = commentCount === 0 ? (<ChatBubbleIcon color="secondary"/>) : (<ChatIcon color="secondary"/>);

    const likeStatement = likeCount === 1 ? "Like" : "Likes";
    const commentStatement = commentCount === 1 ? "Comment" : "Comments";

    const editButton = admin ? (
      <EditPostDetails postId={postId}/>
    ) : null

    const deleteButton = admin ? (
      <DeletePost postId={postId}/>
    ) : null

    return (
    <Card className={classes.card} elevation={0}>
    {/* <CardMedia image={mediaIcon} title="Media Icon" className={classes.mediaIcon} /> */}
    <CardContent className={classes.cardContent}>
      <div className={classes.tagContainer}>
      <div className={classes.imageDateLocationContainer}>
      {/* <div className={classes.mediaIcon}> */}
      {mediaIcon}
      {/* </div> */}
      <div className={classes.dateLocation}>
      <Typography variant="body2" color="info" sx={{color:"#747474"}}>{dayjs(createdAt).fromNow()}</Typography>
      <Typography variant="body2" color="info" sx={{color:"#747474"}}>{location}</Typography>
      
      </div>
      
      </div>
      { !isEmpty(tag) && 
      <Chip label={tag} icon={<TagIcon/>} variant={FILTER_tag === tag ? "contained" : "outlined"} color={FILTER_tag === tag ? "primary" : "secondary"} size="small" sx={{fontWeight:"bold"}}/>
      }
      
      </div>
    
    <Typography variant="body1">{content}</Typography>
    
    {media}
    <div className={classes.postInteractionContainer}>
    <div className={classes.postInteraction}>
    <LikeButton postId={postId} className={classes.postInteractionButton}/>
    <Typography variant="subtitle2" color="info" sx={{color:"#747474"}}>{likeCount} {likeStatement}</Typography>
    </div>
    <div className={classes.postInteraction}>
    <MyButton tip="Comment" className={classes.postInteractionButton} onClick={this.handler}>
      {chatIcon}
    </MyButton>
    <Typography variant="subtitle2" color="info" sx={{color:"#747474"}} className={classes.commentInteraction} onClick={this.handler}>{commentCount} {commentStatement}</Typography>
    </div>
    
    </div>
    <div className={classes.adminPostInteraction}>
    {editButton}
    {deleteButton}
    </div>
    {this.state.openDialog && postId && 
    <PostDialog postId={postId} openWithClick={this.state.openDialog} openDialog={this.props.openDialog} handler = {this.handler} sx={{overflow:'hidden'}}/>
    }
    </CardContent>
    </Card>
    
    )
  }
}

Post.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
  setPostInitial: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  data: state.data
})

const mapActionsToProps = {
  setPostInitial
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Post));
