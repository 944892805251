import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors, editTagDetails } from '../redux/actions/dataActions';
import { isEmpty } from '../util/validation';

// MUI Stuff
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// Icons
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { mobileMediaQuery } from '../util/Global';
import MyButton from '../util/MyButton';


const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    
  },
  progressSpinner: {
    position: 'absolute'
  },
  editTagForm:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    gap:10,
    [`@media ${mobileMediaQuery}`]: {
      flexDirection: "column",
    },
  },
  tag:{
    margin:"6px 3px 0px 3px",
    fontWeight:"bold"
},
postDetailsBanner: {
  display: "flex",
  justifyContent: "space-between",
  padding: "5px 20px",
  alignItems: "center",
  [`@media ${mobileMediaQuery}`]: {
    padding: "5px",
  },
},
})



class EditTag extends Component {
  state = {
    open: false,
    errors: {},
    name:''
  };

  handleOpen = () => {
    const { tagId, tagName } = this.props;
    this.setState({ tagId, name: tagName, open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    this.props.editTagDetails(this.state.tagId,this.state.name);
    this.setState({ open: false });
  };


  render() {
    const { errors } = this.state;
    const { classes, data ,UI: { loading } } = this.props;

    return (
      <Fragment>
        <Chip
            variant=  "outlined"
            onClick={this.handleOpen}
            label="Edit"
            icon={<EditIcon/>}
            color= "secondary"
            size="medium"
            className={classes.tag}
          /> 
        
              <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="sm">
              <div className={classes.postDetailsBanner}>
          <DialogTitle>
            Edit Tag
          </DialogTitle>
          <MyButton
            tip="Close"
            onClick={this.handleClose}
            tipClassName={classes.closeButton}
          >
            <CloseIcon />
          </MyButton>
        </div>
                  <DialogContent className={classes.editTagForm}>

                

                    {/* <form> */}
                      <TextField
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      label="Tag Name"
                      variant="outlined"
                      placeholder="Tag name..."
                      error={errors.name ? true : false}
                      helperText={errors.name}
                      className={classes.textFields}
                      onChange={this.handleChange}
                      />

                    
                    <Button type="button" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading || isEmpty(this.state.name)}>
                      Submit
                      {loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
                      
                    </Button>
                    {/* </form> */}
                  </DialogContent>
              </Dialog>
      </Fragment>
      );
  }
}

EditTag.propTypes = {
  editTagDetails: PropTypes.func.isRequired,
  tagId: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { editTagDetails, clearErrors }
)(withStyles(styles)(EditTag));