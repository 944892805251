import PropTypes from 'prop-types';
import { Component } from 'react';
import MediaQuery from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import { mobileMediaQuery } from '../util/Global';


// Redux Stuff
import { connect } from 'react-redux';
import { getUserData } from '../redux/actions/dataActions';

// My Components
import MobileNavBar from '../components/MobileNavBar';
import NavMenu from '../components/NavMenu';
import StaticProfile from '../components/StaticProfile';

// MUI Stuff
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';


const styles = (theme) => ({
  ...theme.spreadThis
})

class user extends Component {


  componentDidMount() {

    if (this.props.user.authenticated === true) {
      const username = this.props.match.params.username;
      this.props.getUserData(username);
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.user.authenticated !== prevProps.user.authenticated)) {
      if (this.props.user.authenticated === true) {
        const username = this.props.match.params.username;
        this.props.getUserData(username);
      }
    }
  }

  render() {

    const { classes, data: { loading, user }, user: { authenticated } } = this.props;

    


    return (
      <div>
        {authenticated ? (

          <div className={classes.mainPageContainer}>
            <Grid container justifyContent="left" spacing={0} className={classes.mainFeedGridContainer}>
              <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
                <div className={classes.navMenuContainer}>
                  <NavMenu />
                </div>
              </Grid>

              <MediaQuery query={mobileMediaQuery}>
                <MobileNavBar />
              </MediaQuery>

              <Grid item sm={8} xs={12} className={classes.mainFeedItemContainer}>
                {user === null || loading ? (
                   <div className="center" style={{paddingTop:200}} key={0}><CircularProgress size={60} thickness={6.5} color="secondary"/></div>  
                ) : (
                  <CSSTransition
                  classNames="quickFade"
                  in={user !== null}
                  appear={true}
                  timeout={600}
                  >
              <StaticProfile profile={user}/>
              </CSSTransition>
                )}
              </Grid>
            </Grid>
          </div>

        ) : (null)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user
});

const mapActionsToProps = { getUserData };

user.propTypes = {
  getUserData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(user));
