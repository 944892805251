import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { editUserDetails } from '../redux/actions/userActions';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// Icons
import FacebookIcon from '../images/social_icons/facebook_icon.png';
import InstagramIcon from '../images/social_icons/instagram_icon.png';
import TiktokIcon from '../images/social_icons/tiktok_icon.png';
import TwitterIcon from '../images/social_icons/twitter_icon.png';

const styles = (theme) => ({
  ...theme.spreadThis,
  socialInput:{
    '& p':{
      padding:"0px 0px 0px 5px",
      margin:0,
      fontSize:12,
      fontWeight:"bold",
      color:"#938d94"
    }
  },
  socialIconContainer:{
    display:"flex",
    alignItems: "center",
     
      '& img':{
        height:20,
      }
  }
})

class EditProfileDetails extends Component {
  state ={
    displayName: '',
    bio: '',
    location: '',
    weblinkURL:'',
    twitterHandle: '',
    tiktokHandle: '',
    instagramHandle: '',
    facebookHandle: '',
    open: false
  };

  mapUserDetailsToState = (credentials) => {
    this.setState({
      displayName: credentials.displayName ? credentials.displayName : '',
      bio: credentials.bio ? credentials.bio : '',
      location: credentials.location ? credentials.location : '',
      weblinkURL: credentials.weblinkURL ? credentials.weblinkURL : '',
      twitterHandle: credentials.twitterHandle ? credentials.twitterHandle : '',
      tiktokHandle: credentials.tiktokHandle ? credentials.tiktokHandle : '',
      facebookHandle: credentials.facebookHandle ? credentials.facebookHandle : '',
      instagramHandle: credentials.instagramHandle ? credentials.instagramHandle : '',
    });
  };
  handleOpen = () => {
    this.setState({ open: true });
    this.mapUserDetailsToState(this.props.credentials);
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    const { credentials } = this.props;
    this.mapUserDetailsToState(credentials);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleSubmit = () => {
    const userDetails = {
      displayName: this.state.displayName,
      bio: this.state.bio,
      location: this.state.location,
      weblinkURL: this.state.weblinkURL,
      twitterHandle: this.state.twitterHandle,
      tiktokHandle: this.state.tiktokHandle,
      facebookHandle: this.state.facebookHandle,
      instagramHandle: this.state.instagramHandle,
    };
    this.props.editUserDetails(userDetails);
    this.handleClose();
  };
  
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* <Tooltip title="Edit Profile" placement="top"> */}
          {/* <IconButton onClick={this.handleOpen} className={classes.button}> */}
            {/* <EditIcon color = "primary" /> */}
          {/* </IconButton> */}
          <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.buttonSecondary}
              onClick={this.handleOpen}
              disableElevation
            >
              Edit Profile
              </Button>
        {/* </Tooltip> */}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit Your Profile Details</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                name="displayName"
                type="text"
                label="Display Name"
                placeholder="The name you'd like to show to the community"
                variant="outlined"
                className={classes.textField}
                value={this.state.displayName}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="bio"
                tpye="text"
                label="Bio"
                multiline
                rows="3"
                placeholder="A short bio about yourself"
                variant="outlined"
                className={classes.textField}
                value={this.state.bio}
                onChange={this.handleChange}
                fullWidth
              />
              
              <TextField
                name="location"
                type="text"
                label="Location"
                placeholder="Where you live"
                variant="outlined"
                className={classes.textField}
                value={this.state.location}
                onChange={this.handleChange}
                inputProps={{ maxLength: 20 }}
                fullWidth
              />

              <TextField
                name="weblinkURL"
                type="text"
                label="Website URL"
                placeholder="A weblink you'd like to display on your profile..."
                variant="outlined"
                className={classes.textField}
                value={this.state.weblinkURL}
                onChange={this.handleChange}
                fullWidth
              />

              <div className={classes.socialInput}>
              <div className={classes.socialIconContainer}>
                <img src={TwitterIcon} className={classes.socialIcon}/>
              <p>twitter.com/{this.state.twitterHandle}</p>
              </div>
              <TextField
                name="twitterHandle"
                type="text"
                label="Twitter Handle"
                placeholder="Your Twitter Handle"
                variant="outlined"
                className={classes.textField}
                value={this.state.twitterHandle}
                onChange={this.handleChange}
                fullWidth
              />
              
              </div>

              <div className={classes.socialInput}>
              <div className={classes.socialIconContainer}>
                <img src={TiktokIcon} className={classes.socialIcon}/>
              <p>tiktok.com/{this.state.tiktokHandle}</p>
              </div>
              <TextField
                name="tiktokHandle"
                type="text"
                label="Tiktok Handle"
                placeholder="Your TikTok Handle"
                variant="outlined"
                className={classes.textField}
                value={this.state.tiktokHandle}
                onChange={this.handleChange}
                fullWidth
              />
              
              </div>

              <div className={classes.socialInput}>
              <div className={classes.socialIconContainer}>
                <img src={FacebookIcon} className={classes.socialIcon}/>
              <p>facebook.com/{this.state.facebookHandle}</p>
              </div>
              <TextField
                name="facebookHandle"
                type="text"
                label="Facebook Handle"
                placeholder="Your Facebook Handle"
                variant="outlined"
                className={classes.textField}
                value={this.state.facebookHandle}
                onChange={this.handleChange}
                fullWidth
              />
             
              </div>

              <div className={classes.socialInput}>
              <div className={classes.socialIconContainer}>
                <img src={InstagramIcon} className={classes.socialIcon}/>
              <p>instagram.com/{this.state.instagramHandle}</p>
              </div>
              <TextField
                name="instagramHandle"
                type="text"
                label="Instagram Handle"
                placeholder="Your Instagram Handle"
                variant="outlined"
                className={classes.textField}
                value={this.state.instagramHandle}
                onChange={this.handleChange}
                fullWidth
              />
              
              </div>
              
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </Fragment>
    );
  }
}



const mapStateToProps = (state) => ({
  credentials: state.user.credentials
});

const mapActionsToProps = { editUserDetails };

EditProfileDetails.propTypes = {
  editUserDetails: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(EditProfileDetails));

