import withStyles from '@mui/styles/withStyles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mediaURLBuilder, mobileMediaQuery } from '../util/Global';

import { deleteComment, loadMoreComments } from '../redux/actions/dataActions';
// MUI
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroller';

const styles = (theme) => ({
  ...theme.spreadThis,
  commentContainer:{
    margin:"20px 0px",
    padding:"0px 20px 20px 0px",
    height:"400px",
    overflow: "auto",

    [`@media ${mobileMediaQuery}`]: { 
      // height:"calc(100% - 10px)",
      // width:"90%",
      height:"auto",
      padding:20,
      paddingBottom:0,
      margin:0,
    },
  },
commentRow:{
display:"flex",
flexDirection:"row",
gap:5
// height:200
  },
  commentImage: {
    height: 30,
    width:30,
    marginTop:2,
    objectFit: 'cover',
    borderRadius: '50%'
  },
  commentBubbleActions:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start"
  },
  commentBubble: {
    backgroundColor:"#f4f6f6",
    padding:"8px 15px",
    borderRadius:"15px",
    // minWidth:"150px"
  },
  userUsername:{
    margin:0,
    fontWeight:"bold",
    fontSize:"14px",
    color:"#1c1d1d"
  },
  commentContent:{
    margin:0,
    fontSize:"14px",
    color:"#1c1d1d"
  },
  commentActionContainer:{
    display:"flex",
    gap:10,
    alignItems:"baseline"
  },
  commentAction:{
    margin:0,
    padding:0,
    fontWeight:"bold",
    color:"gray",
    fontSize:"10px"
  },
  commentData: {
    marginLeft: 0
  }
});

class Comments extends Component {

  state = {
    commentSectionHeight: 400
  }

  deleteComment = (postId,commentId) => {
    this.props.deleteComment(postId,commentId);
  };

  componentDidUpdate(prevProps) {
    
    if (this.props.sheetHeight !== prevProps.sheetHeight) {    
        this.setState({commentSectionHeight:this.props.sheetHeight})
        
    }
   
  }

  render() {
    const { data:{post, lastKeyComment}, classes, user:{admin, credentials:{username}}} = this.props;

    
    const handleLoadMoreComments = () => {
      if (lastKeyComment !== null && lastKeyComment !== undefined) {
        this.props.loadMoreComments(lastKeyComment,post.postId);
      }
    };

    let commentSection = 
    <InfiniteScroll
    pageStart={1}
    initialLoad={false}
    loadMore={() => { handleLoadMoreComments() }}
    hasMore={lastKeyComment !== "DONE"}
    loader={<div className="center" key={0}><CircularProgress size={20} thickness={2.5} color="secondary"/></div>}
    useWindow={false}
    threshold={800}
    style={{ width:"100%" }}
  >
      {post.comments.map((comment, index) => {
          const { body, createdAt, userImage, userUsername, postId, commentId } = comment;
          return (
            <Fragment key={createdAt}>
              
               <div className={classes.commentRow}>
               <Link to={`/users/${userUsername}`}>
                    <img
                      src={mediaURLBuilder(userImage)}
                      alt="comment"
                      className={classes.commentImage}
                    />
                    </Link>
                  <div className={classes.commentBubbleActions}>
                  <div className={classes.commentBubble}>
               
                      <a href={`/users/${userUsername}`}><p className={classes.userUsername}>{userUsername}</p></a>
               
                      {/* <Typography variant="body2" color="textSecondary">
                        {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')}
                      </Typography> */}
                      <hr className={classes.invisibleSeparator} />
                      <Typography variabnt="body1" className={classes.commentContent}>{body}</Typography>
                    </div>
                    <div className={classes.commentActionContainer}>
                    <p className={classes.commentAction} sx={{color:"#747474"}}>{dayjs(createdAt).fromNow()}</p>
                    { (username === userUsername || admin) ? (
                      <Button onClick={() => this.deleteComment(postId,commentId)}>
                        <p className={classes.commentAction}>Delete</p>
                      </Button>
                    ):(null)}
                    </div>
             </div>
</div>
              {index !== post.comments.length - 1 && (
                <hr className={classes.invisibleSeparator} />
              )}
            </Fragment>
          );
        })}
      </InfiniteScroll>

    return (
      <Box className={classes.commentContainer} sx={{height:`${(Math.max(350,this.state.commentSectionHeight - 100))}px !important`,}}>
     
        {commentSection}
        
     </Box> 
    );
  }
}

Comments.propTypes = {
  bottomSheetComponent: PropTypes.object.isRequired,
  loadMoreComments:  PropTypes.func.isRequired,
  deleteComment:  PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI,
  data: state.data,
})

export default connect(mapStateToProps, { loadMoreComments,deleteComment })(withStyles(styles)(Comments));
