import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_MESSAGE, STOP_LOADING_UI } from '../types';

const intiialState = {
  loading: false,
  message: null,
  errors: {}
}

export default function(state = intiialState, action){
  switch(action.type){
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        message: null
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: {},
        message: null
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true
      };
    case STOP_LOADING_UI:
        return {
          ...state,
          loading: false
        };
    case SET_MESSAGE:
    return {
      ...state,
      message: action.payload
    };
    default:
      return state;
  }
}