import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { clearErrors, getTerms, uploadTerms } from '../redux/actions/dataActions';

import { convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// MUI Stuff
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10,
    display:"flex",
    alignItems:"center",
    gap:20
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '4%'
  },
  tag:{
    margin:"5px",
    fontWeight:"bold"
},
textEditorWrapper:{
    maxWidth:"1000px",
  objectPosition: "center"
  },
  textEditor:{
    minHeight:"300px",
    border:"1px #dfe4ea solid",
    borderRadius:"15px",
    padding:"20px 50px"
  },
  textEditorToolbar:{
    
  },
})


// const emptyTerms = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};
// const emptyTermsState = convertFromRaw(emptyTerms);

class EditTerms extends Component {
  state = {
    open: false,
    termsContent: null,
    submited:false
  };

  componentDidMount() {
    if (this.props.data.documents.terms === null){
    this.props.getTerms();
    }
  }

  handleOpen = () => {
    this.props.handler()
  };
  handleClose = () => {
    this.props.handler()
  };

  onEditorStateChange = (termsContent) => {
    this.setState({termsContent,submited:false});
  };


  handleSubmit = (event) => {
    let uploadTermsState =  JSON.stringify(convertToRaw(this.state.termsContent.getCurrentContent()));
    this.props.uploadTerms({content:uploadTermsState})
    this.setState({termsContent:null,submited:true})
    // this.handleClose()
  };


  render() {
   
    const { classes, data ,UI: { loading, errors } } = this.props;

    return (
      <Fragment>
           
        
              <Card hidden={!this.props.open} onClose={this.handleClose} elevation={0}>
                <Button tip="Close" onClick={this.handleClose}>
                  <ArrowBackIcon color="info" />
                  </Button>
                  
                  <DialogTitle>Edit Terms & Conditions</DialogTitle>
                  <DialogContent>
                
                {  data.documents.terms === null || this.props.open === false ? 
                (<CircularProgress/>):

                (<Editor
                toolbarClassName={classes.textEditorToolbar}
                wrapperClassName={classes.textEditorWrapper}
                editorClassName={classes.textEditor}
                onEditorStateChange={this.onEditorStateChange}
                editorState={this.state.termsContent === null ? data.documents.terms : this.state.termsContent}
              />) 
            }
                    
                   
                    {this.state.submited ? (
                        <div className={classes.submitButton}>
                        <p>{"Terms & Conditions Uploaded"}</p>
                        <CheckCircleOutlineIcon sx={{color:"green"}} fontSize="large" />
                   </div>
                   ) : (
<Button variant="contained" color="primary" className={classes.submitButton} onClick={this.handleSubmit} disabled={loading || this.state.termsContent === null}>
Submit
{loading && <CircularProgress size={30} className={classes.progressSpinner}/>}
</Button>
                    )}
                    
                  </DialogContent>
              </Card>
      </Fragment>
      );
  }
}

EditTerms.propTypes = {
getTerms: PropTypes.func.isRequired,
  uploadTerms: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
});

export default connect(
  mapStateToProps,
  { getTerms, uploadTerms, clearErrors }
)(withStyles(styles)(EditTerms));