import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { connect } from 'react-redux';
import { votePoll } from '../redux/actions/dataActions';


import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function range(start, end) {
    return Array.apply(0, Array(end - 1))
      .map((element, index) => index + start);
  }

const styles = (theme) => ({
    ...theme.spreadThis,
    pollOptionsContainer:{
        display:"flex",
        flexDirection:"column",
        padding:"10px 30px",
        background:"#f4f6f6",
        borderRadius:15,
        gap:10

    },
    voteButton:{
      
    },
    resultLabel:{
      display:"flex",
      gap:15,
      alignItems:"baseline",
      margin:5,
      '& p:nth-child(1)':{
        margin:0,
        fontWeight:"bold",
        fontSize:18,
      },
      '& p:nth-child(2)':{
        margin:0,
        fontWeight:"bold",
        fontSize:14,
        color:"#938d94"
      }
    },
    pollResultBarVoted:{
      background:"#3a4387",
      height:"30px", 
      borderRadius:5,
    },
    pollResultBarNonvoted:{
      background:"#938d94",
      height:"30px", 
      borderRadius:5,
    },
    pollMiscInfo:{
      margin:0,
      fontWeight:"bold",
      fontSize:14,
      color:"#938d94"
    }
})

export class Poll extends Component {

    state = {
        showResults:false,
        selectedOption:null,
        totalVotes: 0,
        resultsArray:null
      }

    votedPost = () => {
        if (this.props.user.votes){
        let this_vote = this.props.user.votes.find((vote) => vote.postId === this.props.postId);
        if (this_vote){
        return [true,this_vote.voteOption];
        }
        else return [false,null];
        }
        else return [false,null];
      };

    componentDidMount(){
        let num_options =  range(1,Object.keys(this.props.pollOptions).length)
        let votesTotal = 0;
        num_options.forEach((option) => {
          votesTotal += this.props.pollOptions[`option${option}`].count
        })
        let voted = this.votedPost()

        this.setState({showResults:voted[0],selectedOption:voted[1],totalVotes:votesTotal,resultsArray:this.props.pollOptions})
      }

      componentDidUpdate(prevProps) {
        if (this.props.user.votes !== prevProps.user.votes ) {  
            let num_options =  range(1,Object.keys(this.props.pollOptions).length)
            let votesTotal = 0;
            num_options.forEach((option) => {
              votesTotal += this.props.pollOptions[`option${option}`].count
        })
            let voted = this.votedPost()  
            this.setState({showResults:voted[0],selectedOption:voted[1],resultsArray:this.props.pollOptions,totalVotes:votesTotal})
        }
      }

  render() {
const { classes, user: { admin, authenticated }, pollOptions} = this.props;


let num_options =  range(1,Object.keys(pollOptions).length)


const getTimeDiff = (seconds) => {
  var days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts*60;
    return ("~ " + days+"d "+hrs+"h "+mnts+"m remaining");
}

const timeRemainingFunc = () => {
  const todaysDate = new Date()
  const pollEndDateTimestamp = Date.parse(pollOptions.endDate);
  const pollEndDate = new Date(pollEndDateTimestamp);
  
  if (todaysDate >= pollEndDate){
    return -1
  }
  const timeDiff = pollEndDate - todaysDate
  const displayTimeDiff = getTimeDiff(timeDiff / 1000);
  
  return displayTimeDiff
};

let timeRemaining = timeRemainingFunc();

const handleChange = (event) => {
    this.setState({selectedOption:event.target.value})
  };


  const handleSubmit = (event) => {
    this.props.votePoll(this.props.postId,this.state.selectedOption)
    // this.setState({showResults:true})
    // this.setState({totalVotes:this.state.totalVotes+1})
  };

  const getWidth = (option) => {
      const tempTotalVotes = this.state.totalVotes === 0 ? 1 : this.state.totalVotes;
      let addition = this.state.selectedOption === `option${option}` ? 0 : 0;
      const percent = (this.state.resultsArray[`option${option}`].count + addition) / tempTotalVotes;
      let width = percent
      width = percent === 0 ? 0.01 : percent
      return [`${width * 100}%`,Math.round(percent *100)]
  }



let options = 
    num_options.map((option) => {
       return (
        <FormControlLabel key={option} value={`option${option}`} control={<Radio />} label={pollOptions[`option${option}`].name} />
        //    <div>
        //    <p>{pollOptions[`option${option}`].name}</p>
        //    </div>
       ); 
})

let results = 
this.state.resultsArray !== null ? (
    num_options.map((option) => {
      const width = getWidth(option)
       return (
           <div key={option}>
          <div className={classes.resultLabel}>
           <p>{pollOptions[`option${option}`].name}</p>
           <p>{width[1]}%</p>
           </div>
           <div className={`option${option}` === this.state.selectedOption ? classes.pollResultBarVoted : classes.pollResultBarNonvoted} style={{width:width[0]}}></div>
           </div>
       ); 
})
): null



    return (
      <div>
      {authenticated ?  (
        <div>
          
      {this.state.showResults || timeRemaining === -1 ? (
          <div className={classes.pollOptionsContainer}>
        <div>{results}</div>
        {timeRemaining !== -1 ?
        (
        <div>
          <p className={classes.pollMiscInfo}>{timeRemaining}</p>
          <p className={classes.pollMiscInfo}>Total Votes: {this.state.totalVotes}</p>
          </div>
        ) 
        : (
          <div>
            <p className={classes.pollMiscInfo}>Poll Over</p>
            <p className={classes.pollMiscInfo}>Total Votes: {this.state.totalVotes}</p>
          </div>
        )
        }
        
     </div>
     ) : (
          <div className={classes.pollOptionsContainer}>
        <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={this.state.selectedOption}
          onChange={handleChange}
        >
            {options}
        </RadioGroup>
      </FormControl>
      <Button className={classes.voteButton} color="secondary" variant="contained" onClick={handleSubmit} disabled={this.state.selectedOption===null || timeRemaining === -1} disableElevation>
        {timeRemaining === -1 ? 
        (<div>Poll Over</div>):
        (<div>Vote</div>)}
        </Button>
        {timeRemaining !== -1 ?
        (
        <div>
          <p className={classes.pollMiscInfo}>{timeRemaining}</p>
          <p className={classes.pollMiscInfo}>Total Votes: {this.state.totalVotes}</p>
          </div>
        ) 
        : (
          <div>
            <p className={classes.pollMiscInfo}>Poll Over</p>
            <p className={classes.pollMiscInfo}>Total Votes: {this.state.totalVotes}</p>
          </div>
        )
        }
        </div>
      )}
        
</div>
      ): (<div></div>)}
      </div>
    )
  }
}

Poll.propTypes = {
    user: PropTypes.object.isRequired,
    pollOptions: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired,
    votePoll: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user
  });
  
  const mapActionsToProps = {
    votePoll,
  };
  
  export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Poll));