import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import WelcomeBackground from '../images/WelcomeBackground.jpg'


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { getTerms } from '../redux/actions/dataActions';

import { CircularProgress } from '@mui/material';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



const styles = (theme) => ({
  ...theme.spreadThis,
  documentPage:{
      margin:"50px 30px"
  },
  documentContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
},
  document:{
      maxWidth:"1056px"
  }
})


export class terms extends Component {
    componentDidMount(){
        if (this.props.data.documents.terms === null){
        this.props.getTerms();
        }
    }
  render() {
    const { classes,data } = this.props;
    return (
      <div className={classes.documentPage}>
          <Button component={Link} to="/">
                  <ArrowBackIcon color="info" />
                  </Button>
          {data.documents.terms === null ? (
              <CircularProgress/>
          ):(
            <div className={classes.documentContainer}>
              <div className={classes.document}>
            <Editor toolbarHidden editorState={data.documents.terms} readOnly={true} />
            </div>
            </div>
          )}
   
      </div>
    );
    }
}

const mapStateToProps = (state) => ({
    data: state.data
});

const mapActionsToProps = { getTerms };

terms.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  getTerms: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(terms));
