
   
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

// Icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
// REdux
import { connect } from 'react-redux';
import { likePost, unlikePost } from '../redux/actions/dataActions';


const styles = (theme) => ({
  ...theme.spreadThis,
  likeButton2: {
    display:"flex",
    gap: 5,
    color:"#938d94",
    background:"#f4f6f6",
    padding:"8px 15px",
    borderRadius:"5px"
  },})

export class LikeButton2 extends Component {
  likedPost = () => {
    if (
      this.props.user.likes &&
      this.props.user.likes.find(
        (like) => like.postId === this.props.postId
      )
    )
      return true;
    else return false;
  };
  likePost = () => {
    this.props.likePost(this.props.postId);
  };
  unlikePost = () => {
    this.props.unlikePost(this.props.postId);
  };
  render() {
    const { classes } = this.props;
    const { authenticated } = this.props.user;
    const likeButton = !authenticated ? (
      <Link to="/login">

      </Link>
    ) : this.likedPost() ? (
      <Button className={classes.likeButton2} color="secondary" onClick={this.unlikePost}>
        <FavoriteIcon color="primary"/>
        <strong>Unlike</strong>
      </Button>
    ) : (
      <Button className={classes.likeButton2} color="secondary" onClick={this.likePost}>
        <FavoriteBorder sx={{color:"#938d94"}}/>
        <strong>Like</strong>
      </Button>
    );
    return likeButton;
  }
}

LikeButton2.propTypes = {
  user: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionsToProps = {
  likePost,
  unlikePost
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(LikeButton2));