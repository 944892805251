import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

import HomeBanner from "../images/home.png";

import { connect } from "react-redux";

import MyButton from "../util/MyButton";
import NavComponents from "./NavComponents";
import UserToolbarMenu from "./UserToolbarMenu";

import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { Link } from "react-router-dom";

import { mobileMediaQuery } from "../util/Global";

// MUI Stuff
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
  navMenuCard: {
    position: "fixed",
    width: "20%",
    ["@media (max-width:1200px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "auto",
    },
    height: "100vh",
    background: "transparent",

    margin: 0,
    padding: 0,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    "&:last-child": {
      paddingBottom: 0,
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",

    [`@media ${mobileMediaQuery}`]: {
      gap: 8,
    },
  },
  homeBanner: {
    margin: "0px",
    height: "50px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "50%",
  },
  homeIconContainer: {
    marginLeft: "14px",
    padding: 0,
    marginTop: -10,
    [`@media ${mobileMediaQuery}`]: {
      marginLeft: "0px",
    padding: 0,
    marginTop: 0,
    }
  },
  navMenuItemContainer: {
    marginRight: 12,
    borderRadius: "150px",
    [`@media ${mobileMediaQuery}`]: {
    padding: "5px 5px",
    }
  },
  navMenuItem: {
    textAlign: "center",
    TextDecoder: "none",
    fontSize: "18px",
  },
  navMenuItemIcon: {
    marginLeft: "20px",
    marginRight: "28px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  navMenuSeparator: {
    border: "none",
    width: "100%",
    borderBottom: "0px solid #dfe4ea",
    margin: "0px",
  },
  adminMenu: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  footer: {
    margin: " 0px 30px",
    color: "#938d94",
    fontSize: "12px",
    "& :visited": {
      color: "#938d94",
    },
  },
});

export class NavDrawer extends Component {
  state = {
    open: false,
  };

  closeDrawer = () => (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: false });
    // event.stopImmediatePropagation()
  };

  openDrawer = () => (event) => {
    this.setState({ open: true });
  };

  render() {
    const {
      classes,
      user: { admin },
    } = this.props;
    let { isOpen } = this.state;

    return (
      <div>
        <Button onClick={this.openDrawer()}>
          <MenuIcon color="info" fontSize="large" />
        </Button>
        <SwipeableDrawer
          anchor="left"
          open={this.state.open}
          onClose={this.closeDrawer()}
          onOpen={this.openDrawer()}
        >
          {/* {list} */}
          <Box sx={{ width: 350 }} role="presentation">
            <Fragment>
              <Card className={classes.navMenuCard} elevation={0}>
                <CardContent
                  onClick={this.closeDrawer()}
                  onKeyDown={this.closeDrawer()}
                >
                  <div className={classes.navMenu}>
                    <div className={classes.menu}>
                      <div className={classes.homeIconContainer}>
                        <MyButton
                          tip="Visit WeGoAlong.com"
                          href="https://wegoalong.com"
                        >
                          <img
                            src={HomeBanner}
                            alt="Icon"
                            className={classes.homeBanner}
                          />
                        </MyButton>
                      </div>

                      <NavComponents />
                    </div>
                  </div>
                </CardContent>
                <CardContent>
                  <UserToolbarMenu />
                </CardContent>

                <CardContent>
                  <MenuItem
                    className={classes.navMenuItemContainer}
                    component={Link}
                    to={"/privacy"}
                  >
                    <Typography
                      className={classes.navMenuItem}
                      color="info"
                      variant="body1"
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginLeft: "20px",
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    className={classes.navMenuItemContainer}
                    component={Link}
                    to={"/terms"}
                  >
                    <Typography
                      className={classes.navMenuItem}
                      color="info"
                      variant="body1"
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginLeft: "20px",
                      }}
                    >
                      Terms & Conditions
                    </Typography>
                  </MenuItem>
                </CardContent>
                {/* <MenuItem className={classes.navMenuItemContainer}>        */}
                <div className={classes.footer}>
                  <Typography
                    className={classes.navMenuItem}
                    color="info"
                    variant="body1"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: "left",
                      marginLeft: "14px",
                    }}
                  >
                    We Go Along © 2022
                  </Typography>
                  {/* </MenuItem> */}
                </div>
              </Card>
            </Fragment>
          </Box>
        </SwipeableDrawer>
      </div>
    );
  }
}

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(NavDrawer));
