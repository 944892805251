import PropTypes from "prop-types";
import { Component } from "react";
import { Link } from "react-router-dom";

// Images
import AppIcon from "../images/Red & Cream Classic Van Summer Holiday Travel Logo With Surfboard And Palm Tree.png";

// Redux
import { connect } from "react-redux";
import { signupUser } from "../redux/actions/userActions";

// My Components
import Navbar from "../components/Navbar";

// MUI
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import MoreInfo from "../util/MoreInfo";

const styles = (theme) => ({
  ...theme.spreadThis,
  dob_picker_container:{
    display:"flex",
    alignItems:"center",
    gap:15,
    width:"100%"
  }
});



export class join extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
      displayName: "",
      DOB: null,
      errors: {},
      showPassword: false,
      consent:false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      username: this.state.username,
      displayName: this.state.displayName,
      DOB: this.state.DOB,
    };
    this.props.signupUser(newUserData, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setDOB = (newValue) => {
    this.setState({ DOB: newValue });
  };

  handleShowPassword = (event) => {
    this.setState({ showPassword: event.target.checked });
  };

  handleConsent = (event) => {
    this.setState({ consent: event.target.checked });
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return (
      <div className="container">
      <Grid container justifyContent="center"  className={classes.welcomePagesContainer}>
        <Navbar/>
        <Grid item sm={4}>
          <img src={AppIcon} alt="Icon" className={classes.icon} />
          <h1 className={classes.pageTitle}>Join</h1>
          <form noValidate onSubmit={this.handleSubmit} className={classes.form}>
            <div className="side-by-side-textfield">
              <TextField
                id="username"
                name="username"
                type="text"
                label="Username"
                className={classes.textField}
                helperText={errors.username}
                error={errors.username ? true : false}
                value={this.state.username}
                onChange={this.handleChange}
                inputProps={{ maxLength: 15 }}
                variant="outlined"
                fullWidth
              />

              <TextField
                id="displayName"
                name="displayName"
                type="text"
                label="Display Name"
                className={classes.textField}
                helperText={errors.displayName}
                error={errors.displayName ? true : false}
                value={this.state.displayName}
                onChange={this.handleChange}
                inputProps={{ maxLength: 30 }}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className={classes.dob_picker_container}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Birth Date"
                  className={classes.textField}
                  errorText={errors.DOB}
                  error={errors.DOB ? true : false}
                  value={this.state.DOB}
                  onChange={(newValue) => {
                    this.setDOB(newValue);
                  }}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <MoreInfo tip="We use your date of birth to confirm that you are over the age of 18." />
            </div>
            {errors.DOB && (
              <Typography variant="body2" className={classes.customError}>
                {errors.DOB}
              </Typography>
            )}

            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              className={classes.textField}
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              type={this.state.showPassword ? "text" : "password"}
              label="Password"
              className={classes.textField}
              helperText={errors.password}
              error={errors.password ? true : false}
              value={this.state.password}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />

            <TextField
              id="confirmPassword"
              name="confirmPassword"
              type={this.state.showPassword ? "text" : "password"}
              label="Confirm Password"
              className={classes.textField}
              helperText={errors.confirmPassword}
              error={errors.confirmPassword ? true : false}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showPassword}
                  onChange={this.handleShowPassword}
                />
              }
              label="Show Password"
            />

<FormControlLabel
              control={
                <Checkbox
                  checked={this.state.consent}
                  onChange={this.handleConsent}
                />
              }
              label={<p style={{fontSize:"12px"}}>I have read and agree to the We Go Along Club <a href="/terms" style={{color:"#3a4387",fontWeight:"bold"}}>Terms & Conditions </a>
              and <a href="/privacy" style={{color:"#3a4387",fontWeight:"bold"}}>Privacy Policy.</a></p>}
            />


            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}

            

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.welcomeButtons}
              disableElevation
              disabled={loading || !this.state.consent}
            >
              Join
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>
            <small>
              Already have an account ? <Link to="/login" style={{color:"#3a4387",fontWeight:"bold"}}>Login here</Link>.
            </small>
          </form>
        </Grid>
      </Grid>
      </div>
    );
  }
}

join.propTypes = {
  classes: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  signupUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(join));
