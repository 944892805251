
import { useRef, useState } from 'react';
import CommentForm from "./CommentForm";
import Comments from "./Comments";

import { BottomSheet } from 'react-spring-bottom-sheet';
import '../css/mobileModal.css';

// MUI
import { CircularProgress } from '@mui/material';




 const CommentsCustomScroller = ({classes,loading,postId,mobileCommentOpen,dismissHandler}) => {
    const [sheetHeight, setSheetHeight] = useState(0);
const sheetRef = useRef()

  return (
   <BottomSheet open={mobileCommentOpen} onDismiss={() => dismissHandler()}
    snapPoints={({ minHeight, height, maxHeight }) => [500, (maxHeight-80)]} 
    ref={sheetRef}
    onSpringStart={() => {
        setSheetHeight(sheetRef.current.height)
      requestAnimationFrame(() =>
        setSheetHeight(sheetRef.current.height)
      )
    }}
    onSpringEnd={() =>
        setSheetHeight(sheetRef.current.height)
    }
    >
      {loading ? (
      <div className={classes.spinnerDiv}>
        <div className="center" key={0}>
          <CircularProgress size={60} thickness={6.5} color="secondary" />
        </div>
      </div>
    ) : (
    <div className={classes.commentContainer}>
    <CommentForm postId={postId} />
    <div className={classes.commentListContainer}>
      <Comments sheetHeight={sheetHeight}/>
    </div>
  </div>
    )}
      </BottomSheet>
  );
}

export default CommentsCustomScroller

  