import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default ({ children, onClick, tip, btnClassName, tipClassName,href }) => (
  <Tooltip title={tip} className={tipClassName} placement="top">
    <IconButton onClick={onClick} className={btnClassName} href={href} size="large">
      {children}
    </IconButton>
  </Tooltip>
);