import withStyles from "@mui/styles/withStyles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import ReactPlayer from "react-player";
import MediaQuery from "react-responsive";
import {
  desktopMediaQuery, mediaURLBuilder, mobileMediaQuery, types_icon
} from "../util/Global";
import Icon from "../util/Icons";
import CommentForm from "./CommentForm";
import Comments from "./Comments";
import LikeButton2 from "./LikeButton2";
import Poll from "./Poll";

// Redux Stuff
import { connect } from "react-redux";
import { clearErrors, clearPost, getPost, loadMoreComments } from "../redux/actions/dataActions";

// MUI Stuff
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

// import Carousel from 'react-material-ui-carousel'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Icons
import { ChatBubbleOutline } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CommentsCustomScroller from "./CommentsCustomScroller";

const styles = (theme) => ({
  ...theme.spreadThis,
  paper: {
   backgroundColor:"#fff",
    borderRadius: "15px",
    overflowX: "hidden",
    overflow:"hidden",
    [`@media ${mobileMediaQuery}`]: {
      // borderRadius: "0px",
      width: "98% !important",
      margin: 0,
    },
  },
  dialogInner:{
    overflowY:"auto",
    width:"100%",
    display:"flex",
    alignItems: "center",
    flexDirection:"column",
    [`@media ${mobileMediaQuery}`]: {
    }
  },
  dialogToolbar: {
    display: "flex",
    width: "95%",
    padding:"10px 0px 0px 10px",
    [`@media ${mobileMediaQuery}`]: {
      width:"100%",
      padding:"10px 0px"
    // position:"absolute",
    // top:"10px",
    // left:"10px",
    }
  },
  imageCarousel:{
    maxHeight:"80vh",
  },
  imageItemContainer:{
    width: "100%",
  display: "flex",
  },
  imageCarouselItem:{
    maxHeight:"80vh",
    width: "100%",
  // height: "100%",
  objectFit: "contain",
  objectPosition: "center",
  },
  soloImageItem:{
    width: "100%",
  display: "flex",
  },
  spinnerDiv:{},
  commentContainer:{
    
  },
  commentListContainer:{
    
  },
  postDetailsAndInteractionContainer:{
    display:"flex",
    flexDirection:"column",
    gap:20,
    maxWidth:"600px",
    width:"100%",
    [`@media ${mobileMediaQuery}`]: {
      width:"80%"
    }
  },
  postInfoContainer:{
    display:"flex",
    flexDirection:"column",
    gap:20,
  },

  infoButtonContainer:{
    display: "flex",
    alignItems: "center",
    gap: 20,
    [`@media ${mobileMediaQuery}`]: {
      flexDirection:"column",
      alignItems:"flex-start"
    },
  },
  imageDateLocationContainer: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  dateLocation:{},
  postInteractionButton:{},
  postDialogGrid:{
    display:"grid",
    gridTemplateColumns:"2fr 1fr",
    // height:"100%",
    gap:20,
    padding: 20,
    overflow:"hidden",
    [`@media ${mobileMediaQuery}`]: {
      overflowY: "auto",
      overflowX: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 0px",
    margin:0,
    width:"100%",
    },
  },
  postDialogGridSecondary:{
    display:"flex",
    flexDirection:"column",
    // height:"100%",
    gap:20,
    padding: 20,
    width:"800px",
    alignItems:"center",
    [`@media ${mobileMediaQuery}`]: {
      width:"85%",
      
    padding:"0px 20px 40px 20px",
    gap:0,
    },
  },
  mediaPhoto:{
    // height:"80vh",
    [`@media ${mobileMediaQuery}`]: {

    }
  },
  mediaVideo:{
    width:"100%",
    height:"100%",
    maxHeight:"100vh",
  },
  mediaArticle:{
    maxWidth:"800px",
    [`@media ${mobileMediaQuery}`]: {
maxWidth:"95%"
    }
  },
  mediaPoll:{
    width:"100%",
    paddingBottom:"20px"
  },
  mediaOther:{
  },
  
  mediaIcon: {
    // height: "100%",
  },
  mobileButtonsContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent: "space-evenly"
  },

  likeButton2: {
    display:"flex",
    gap: 5,
    color:"#938d94",
    background:"#f4f6f6",
    padding:"8px 15px",
    borderRadius:"5px"
  }
});

class PostDialog extends Component {
  state = {
    open: false,
    oldPath: "",
    newPath: "",
    fullScreenImage: "",
    mobileCommentOpen:false
  };

  componentDidMount() {
    if (this.props.openDialog || this.props.openWithClick) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.openWithClick !== prevProps.openWithClick) &
      this.props.openWithClick
    ) {
      this.handleOpen();
    }
    
  }

   


  
  handleOpen = () => {
    let oldPath = window.location.pathname;

    const { postId } = this.props;
    const newPath = `/posts/${postId}`;

    if (oldPath === newPath) oldPath = "/";

    window.history.pushState(null, null, newPath);

    this.setState({ open: true, oldPath, newPath });
    if (this.props.post.postId !== this.props.postId) {
      this.props.getPost(this.props.postId);
    } 
    else {
      if (this.props.post["comments"] === undefined){
        const lastKeyComment = new Date().toISOString()
        this.props.loadMoreComments(lastKeyComment,postId);
      }
    }
    if (!this.props.openWithClick) {
      this.props.handler();
    }
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      // window.history.pushState(null, null, this.state.oldPath);
      // this.props.handler();
      this.setState({ open: false });
      // this.props.clearErrors();
    });
        
  };
  handleClose = () => {
    window.history.pushState(null, null, this.state.oldPath);
    this.props.handler();
    this.setState({ open: false });
    this.props.clearErrors();
  };

  handleFullScreenClose = () => {
    this.setState({
      fullScreenImage: "",
    });
  };


  dismissMobileCommentOpen = () => {
    this.setState({mobileCommentOpen:false})
  }
  render() {

    

    const {
      classes,
      post: {
        postId,
        type,
        content,
        articleContent,
        pollOptions,
        createdAt,
        dataURLs,
        location,
        likeCount,
        commentCount,
        comments,
      },
      user: { admin, authenticated },
      UI: { loading },
      loadingComments,
      openWithClick,
    } = this.props;

    

    let mediaIcon = (
      <Icon
        className={classes.mediaIcon}
        fontSize="large"
        color="secondary"
        component={types_icon[type]}
      />
    );

    let media;
    let contentState = "";
    let editorState = "";

    if (type === "photo" && dataURLs.length > 0) {
      media = <Carousel
                  autoPlay={false}
                  dynamicHeight
                  infiniteLoop
                  useKeyboardArrows
                  showThumbs={false}
                  swipeable
                  showIndicators={false}
                  emulateTouch
                  className={classes.imageCarousel}
                >
                  {dataURLs.map((item, i) => (
                    <div className={classes.imageItemContainer}>
                  <img key={i} src={mediaURLBuilder(item)} className={classes.imageCarouselItem}/>
                  </div>
                   ))}
                </Carousel>
    } 
    
    else if (type === "photo" && dataURLs.length === 0) {
      media =  <img
      src={mediaURLBuilder(dataURLs[0])}
      className={classes.soloImageItem}
    />;
    } 
    
    else if (type === "video") {
      media = (
        <ReactPlayer
          url={dataURLs[0].video}
          playing
          controls
          width="100%"
          height="80%"
          style={{maxHeight:"100vh"}}
          alignItems="top"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
        />
      );
    } 
    
    else if (type === "article") {
      const DBEditorState = JSON.parse(articleContent);
      contentState = convertFromRaw(DBEditorState);
      editorState = EditorState.createWithContent(contentState);
      media = <Editor toolbarHidden editorState={editorState} readOnly={true} />
    } 
    
    else if (type === "poll") {
      media = <Poll key={postId} postId={postId} pollOptions={pollOptions} />;
    } 


    const commentSection = 
    comments === undefined ? (
      <div className={classes.spinnerDiv}>
        <div className="center" key={0}>
          <CircularProgress size={60} thickness={6.5} color="secondary" />
        </div>
      </div>
    ) : (
    <div className={classes.commentContainer}>
    <CommentForm postId={postId} />
    <div className={classes.commentListContainer}>
      <Comments/>
    </div>
  </div>
    )

    
    const mobileCommentSection = <CommentsCustomScroller classes={classes} loading = {loading} postId = {postId} mobileCommentOpen = {this.state.mobileCommentOpen} dismissHandler = {this.dismissMobileCommentOpen}/>

    const postDetailsAndInteraction = 
    <div className={classes.postDetailsAndInteractionContainer}>
          
            <div className={classes.postInfoContainer}>

              <div className={classes.infoButtonContainer}>
              <div className={classes.imageDateLocationContainer}>
                {mediaIcon}
                <div className={classes.dateLocation}>
                  <Typography variant="body2" sx={{color:"#747474"}}>
                    {dayjs(createdAt).fromNow()}
                  </Typography>
                  <Typography variant="body2" sx={{color:"#747474"}}>
                    {location}
                  </Typography>
                </div>
                </div>
                <MediaQuery query={desktopMediaQuery}>
                <LikeButton2
                  postId={postId}
                  className={classes.postInteractionButton}
                />
                </MediaQuery>
              </div>

              <Typography variant="body1">{content}</Typography>

            </div>
            <MediaQuery query={desktopMediaQuery}>
           {commentSection}
           </MediaQuery>
            <MediaQuery query={mobileMediaQuery}>
              <div className={classes.mobileButtonsContainer}>
              <LikeButton2
                  postId={postId}
                  className={classes.postInteractionButton}
                />
            <Button className={classes.likeButton2} color="secondary" onClick={()=>this.setState({mobileCommentOpen:true})}>
        <ChatBubbleOutline sx={{color:"#938d94"}}/>
        <strong>COMMENTS</strong>
      </Button>
      </div>
           {mobileCommentSection}
           </MediaQuery>
          
        </div>


    const dialogMarkup = 
    // loading ? (
    //   <div className={classes.spinnerDiv}>
    //     <div className="center" key={0}>
    //       <CircularProgress size={60} thickness={6.5} color="secondary" />
    //     </div>
    //   </div>
    // ) : (
      
      <div className={
        ["photo","video"].indexOf(type) > -1
         ? 
        classes.postDialogGrid :
        classes.postDialogGridSecondary
        }>
        
          {type == "photo" && dataURLs && (
          <div className={classes.mediaPhoto}>{media}</div>
          )}
          {type == "video" && (
            <div className={classes.mediaVideo}>{media}</div>
          )}

          {type == "poll" && (
            <div className={classes.mediaPoll}>{media}</div>
          )}

          {type == "article" && (
            <div className={classes.mediaArticle}>{media}</div>
          )}

          {["photo","video","poll","article"].indexOf(type) === -1 && (
            <div className={classes.mediaOther}>{media}</div>
          )}
        
        {postDetailsAndInteraction}
        
      </div>;
    // );
    return (
      <Fragment>
        <MediaQuery query={desktopMediaQuery}>


          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth={["photo","video","article"].indexOf(type) > -1 ? "xl" : "lg"}
            classes={{ paper: classes.paper }}
          >
            <div className={
        ["photo","video"].indexOf(type) > -1
         ? 
        null :
        classes.dialogInner}>
            <div className={classes.dialogToolbar}>
              <Button
                color="secondary"
                onClick={this.handleClose}
                className={classes.closeButton}
              >
                <ArrowBackIcon />
              </Button>
              <div></div>
            </div>

            
              {dialogMarkup}
              </div>
          </Dialog>


        </MediaQuery>
        <MediaQuery query={mobileMediaQuery}>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            classes={{ paper: classes.paper }}
          >
            <div className={classes.dialogInner}>
            <div className={classes.dialogToolbar}>
              <Button
                color="secondary"
                onClick={this.handleClose}
                className={classes.closeButton}
              >
                <ArrowBackIcon />
              </Button>
              <div></div>
            </div>
            
              {dialogMarkup}
              </div>
          </Dialog>
        </MediaQuery>
      </Fragment>
    );
  }
}

PostDialog.propTypes = {
  getPost: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
  loadMoreComments: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  loadingComments: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.data.post,
  UI: state.UI,
  user: state.user,
  loadingComments: state.data.loadingComments,
});

const mapActionsToProps = {
  getPost,
  loadMoreComments,
  clearPost,
  clearErrors,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PostDialog));
