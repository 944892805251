import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import CreatePost from '../components/CreatePost';
import HomeBanner from '../images/home.png';
import MyButton from '../util/MyButton';
import NavComponents from './NavComponents';
import UserToolbarMenu from './UserToolbarMenu';


import { connect } from 'react-redux';

// MUI Stuff
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Icons


const styles = (theme) => ({
  ...theme.spreadThis,
  navMenuCard: {
    
    position: 'fixed',
    width:'23.3%',
    height:"100vh",
    background:"transparent",
    // background:"#fff",
    margin:0,
padding:0,
paddingBottom: 0,
display:"flex",
flexDirection:"column",
justifyContent:"space-between",

"&:last-child": {
  paddingBottom: 0
}
  },
  menu:{
    display:"flex",
    flexDirection:"column",
    gap:"12px",
    paddingLeft:20
  },
  homeBanner: {
margin:"0px",
height:"50px",
boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
borderRadius:"50%"
  },
  homeIconContainer: {
    // textAlign:"center",
    // display:"flex",
    marginLeft:"14px",
    padding:0,
    marginTop:-10,
    // marginRight:"auto",
    // marginBottom:"5px",
  },
  navMenuItemContainer:{
    marginRight:12,
    
    // border:"2px #f4f6f6 solid",
    borderRadius:"150px",
  },
  navMenuItem: {
    textAlign: "center",
    // paddingTop:"5px",
    TextDecoder: "none",
    fontSize:"18px",
    // marginLeft:"28px"
  },
  navMenuItemIcon: {
    marginLeft:"20px",
    marginRight:"28px",
    fontSize:"24px",
    fontWeight:"bold"
  },
  navMenuSeparator: {
    border:'none',
    width:'100%',
    borderBottom:"0px solid #dfe4ea",
    margin:"0px"
  },
  adminMenu: {
    display:"flex",
    flexDirection:"column",
    gap:"12px",
  },
  footer:{
    margin:"30px",
    color:"#938d94",
  fontSize:"12px",
  '& a': {
    color:"#938d94"
  },
  '& a:visited': {
    color:"#938d94"
  }
  }
});

export class NavMenu extends Component {
  
  render() {
    const { classes, user: { admin } } = this.props;
    
    return (
      <Fragment>
        
        
      <Card className={classes.navMenuCard} elevation={0} >
        
      <CardContent className={classes.navMenuCard}>
        
    <div className={classes.navMenu}>
    
    <div className={classes.menu}>
    
    <div className={classes.homeIconContainer}>
        <MyButton tip="Visit WeGoAlong.com" href="https://wegoalong.com">
      <img src={HomeBanner} alt="Icon" className={classes.homeBanner}/>
      </MyButton>
      </div>
    
      <NavComponents/>
    
    <UserToolbarMenu />
    </div>
    </div>
    
    <div className={classes.menu}>
    {admin ? (
      <div className={classes.adminMenu}>

            <CreatePost/>
  
    </div>
          ) : null}
    </div>
    <div className={classes.menu}>
      
    <div className={classes.footer}>
    <a href="/privacy" style={{color:"#938d94"}}>Privacy</a> · <a href="/terms" style={{color:"#938d94"}}>Terms & Conditions</a><br/>We Go Along © 2022
    </div>
    </div>
    </CardContent>
    </Card>

    
    </Fragment>
      );
  }
}

NavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

// export default withStyles(styles)(NavMenu);


const mapStateToProps = state => ({
  user: state.user
})

export default connect(
  mapStateToProps
)(withStyles(styles)(NavMenu));