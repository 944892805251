import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import HomeBanner from '../images/icon_club7.png';

// MUI Stuff
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// Icons

import FilterDrawer from './FilterDrawer';
import NavDrawer from './NavDrawer';


const styles = (theme) => ({
  ...theme.spreadThis,
  mobileNavButtonLeft:{
    width:100,
    textAlign:"left"
  },
  mobileNavButtonCenter:{
    width:100,
    textAlign:"center"
  },
  mobileNavButtonRight:{
    width:100,
    textAlign:"right"
  },
  navBarWelcomeButton:{
    fontWeight:"600"
  },
  homeBanner: {
    margin:"0px",
    height:"40px",
    // boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    // borderRadius:"50%"
      },
})

class MobileNavBar extends Component {
  render() {
    const { classes, user: {authenticated, admin} } = this.props;
    // let color = authenticated ? "white" : "white";
    return (
      <AppBar position="fixed" elevation={0} sx={{backgroundColor:"#fdfdfd"}}>
        <Toolbar className="nav-container">
        
        <div className={classes.mobileNavButton}>
        <NavDrawer />
        
        </div>

        <div className={classes.mobileNavButton}>
          <Link to="/">
        <img src={HomeBanner} alt="Icon" className={classes.homeBanner}/>
        </Link>
        </div>

        <div className={classes.mobileNavButton}>
           <FilterDrawer/>
        </div>
        </Toolbar>
        
      </AppBar>
    );
  }
}


MobileNavBar.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(MobileNavBar));
