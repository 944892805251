import withStyles from "@mui/styles/withStyles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { convertFromRaw, convertToRaw } from "draft-js";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import {
  clearCreatePostSuccess, clearErrors, createPost
} from "../redux/actions/dataActions";
import { icon_set, mobileMediaQuery } from "../util/Global";

import Icon from "../util/Icons";

// MUI Stuff
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import LocalizationProvider from "@mui/lab/LocalizationProvider";

import DateTimePicker from "@mui/lab/DateTimePicker";

// Icons

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Create";
import MyButton from "../util/MyButton";

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10,
  },
  progressSpinner: {
    position: "absolute",
  },
  closeButton: {
    // position: 'absolute',
    // left: '91%',
    // top: '4%'
  },
  createPostDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    margin: "20px",
    [`@media ${mobileMediaQuery}`]: {
      margin: "5px",
    },
  },
  createPostForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    gap: "10px",
    width:"100%",
  },
  latlon: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  navMenuItem: {
    textAlign: "center",
    TextDecoder: "none",
    fontSize: "18px",
    backgroundColor: "#f4f6f6",
    borderRadius: "50px",
    marginRight: 12,
  },
  navMenuItemIcon: {
    marginLeft: "22px",
    marginRight: "24px",
    fontSize: "24px",
  },
  textEditorWrapper: {
    maxWidth: "1000px",
    objectPosition: "center",
  },
  textEditor: {
    minHeight: "300px",
    border: "1px #dfe4ea solid",
    borderRadius: "15px",
    padding: "20px 50px",
  },
  textEditorToolbar: {},
  error: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    marginLeft: "14px",
    marginTop: "3px",
  },
  postDetailsBanner: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 20px",
    alignItems: "center",
    [`@media ${mobileMediaQuery}`]: {
      padding: "5px",
    },
  },
  successContainer:{
    display:"flex",
    flexDirection:"column",
    padding:"40px",
    alignItems:"center"
  }
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const post_types = ["photo", "text", "video"]; //"article", 

const articleContent = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Initialized from content state.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};
const articleContentState = convertFromRaw(articleContent);

class CreatePost extends Component {
  state = {
    open: false,
    articleHTML: false,
    maxWidth: "sm",
    creatingTag: false,
    errors: {},
    done: false,
    type: "text",
    content: "",
    dataURLs: [],
    location: "",
    lat: "",
    lon: "",
    tag: "",
    articleEditorState: articleContentState,
    formData: null,
    videoURL: "",
    thumbnailName: "",
    pollOptions: { 1: "", 2: "" },
    date: new Date().addDays(1),
    expectToHavePosted:false
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ content: "", open: false, errors: {} });
    }
  }
  handleOpen = () => {
    this.setState({ open: true, done: false });
  };
  handleClose = (event) => {
    this.props.clearErrors();
    this.props.clearCreatePostSuccess()
    this.setState({ open: false, errors: {} });
  };

  handleDone = () => {
    this.setState({ done: true });
  };

  handleNotDone = () => {
    this.setState({ done: false, errors: {} });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePollOptionChange = (event) => {
    this.state.pollOptions[parseInt(event.target.name)] = event.target.value;
    this.setState({ pollOptions: this.state.pollOptions });
  };

  addPollOption = () => {
    this.state.pollOptions[Object.keys(this.state.pollOptions).length + 1] = "";
    this.setState({ pollOptions: this.state.pollOptions });
  };

  removePollOption = (option) => {
    delete this.state.pollOptions[option];
    this.setState({ pollOptions: this.state.pollOptions });
  };

  handleSelectDate = (newValue) => {
    this.setState({ date: newValue });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.createPost({
      content: this.state.content,
      articleContent:
        this.state.type === "article"
          ? JSON.stringify(
              convertToRaw(this.state.articleEditorState.getCurrentContent())
            )
          : null,
      type: this.state.type,
      dataURLs: this.state.dataURLs,
      videoURL: this.state.videoURL,
      location: this.state.location,
      locationCoords: [this.state.lat, this.state.lon],
      tag: this.state.tag,
      formData: this.state.formData,
      pollOptions: this.state.pollOptions,
      date: this.state.date,
    });
  };

  handleImageUploads = (event) => {
    const images = event.target.files;
    const formData = new FormData();
    const image_urls = [];
    for (let i = 0; i < images["length"]; i++) {
      formData.append(images[i].name, images[i], images[i].name);
      image_urls.push(images[i].name);
    }
    this.setState({ formData: formData });
    this.setState({ dataURLs: image_urls });
  };

  handleImageUploadsPress = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  handleVideoThumbnailUpload = (event) => {
    const thumbnail = event.target.files[0];
    const formData = new FormData();
    formData.append(thumbnail.name, thumbnail, thumbnail.name);
    this.setState({ formData: formData });
    this.setState({ thumbnailName: thumbnail.name });
  };

  handleThumbnailUploadsPress = () => {
    const fileInput = document.getElementById("thumbnailInput");
    fileInput.click();
  };

  setPhoto = (event) => {
    this.setState({ maxWidth: "sm", type: "photo", dataURLs: [] });
  };
  setVideo = (event) => {
    this.setState({ maxWidth: "sm", type: "video", dataURLs: [] });
  };
  setArticle = (event) => {
    this.setState({ maxWidth: "xl", type: "article", dataURLs: [] });
  };
  setText = (event) => {
    this.setState({ maxWidth: "sm", type: "text", dataURLs: [] });
  };
  setAnnounce = (event) => {
    this.setState({ maxWidth: "sm", type: "announce", dataURLs: [] });
  };
  setPoll = (event) => {
    this.setState({ maxWidth: "sm", type: "poll", dataURLs: [] });
  };
  setFeedback = (event) => {
    this.setState({ maxWidth: "sm", type: "feedback", dataURLs: [] });
  };

  setTag = (event) => {
    this.setState({ tag: event.target.value });
  };

  onEditorStateChange = (articleEditorState) => {
    this.setState({
      articleEditorState,
    });
  };

  handleRemoveUpload = (index, name) => {
    this.state.dataURLs.splice(index, 1);
    this.state.formData.delete(name);
    this.setState({
      dataURLs: this.state.dataURLs,
      formData: this.state.formData,
    });
  };

  render() {
    dayjs.extend(relativeTime);
    const { errors } = this.state;
    const {
      classes,
      data,
      UI: { loading },
    } = this.props;

    let tags =
      !loading && data.tags.length > 0 ? data.tags : [{ name: "None" }];

    let uploadList = !loading
      ? this.state.dataURLs.map((upload, index) => {
          return (
            <Chip
              key={index}
              label={upload}
              variant="outlined"
              onDelete={() => this.handleRemoveUpload(index, upload)}
              //  onClick={() => ()}
              color="info"
              size="medium"
              className={classes.tag}
            />
          );
        })
      : null;

    let pollOptionFields = !loading
      ? Object.keys(this.state.pollOptions).map((key, index) => {
          return (
            <div>
              <TextField
                key={key}
                id={this.state.pollOptions[index + 1]}
                name={`${index + 1}`}
                type="text"
                label={this.state.pollOptions[index + 1]}
                variant="outlined"
                placeholder={`Poll Option ${index + 1}...`}
                error={errors.pollOptions ? true : false}
                helperText={errors.pollOptions}
                className={classes.textFields}
                onChange={this.handlePollOptionChange}
                disabled={this.state.done}
                fullWidth
              />
              {index >= 2 && (
                <Button onClick={() => this.removePollOption(index + 1)}>
                  Remove
                </Button>
              )}
            </div>
          );
        })
      : null;

    let endsInField = !loading ? (
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Poll End Date"
            value={this.state.date}
            onChange={(newValue) => {
              this.handleSelectDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <p>{dayjs(this.state.date).fromNow()}</p>
      </div>
    ) : null;

    let dialogContent = 
    data.createPostSuccess !== null ? (
      data.createPostSuccess === true ? (
      <div className={classes.successContainer}>
        <CheckIcon fontSize="large"/>
        <p>Successfully Created Post</p>
        <Button
            onClick={this.handleClose}
            color="secondary"
          >
            Finish
          </Button>
      </div>
      ) : (
        <div className={classes.successContainer}>
        <CloseIcon fontSize="large"/>
        <p>Failed To Create Post</p>
        <p>Please Contact Chris & Try Again Later</p>
        <Button
            onClick={this.handleClose}
            color="secondary"
          >
            Finish
          </Button>
      </div>
      )
    ) :
    (
      <Fragment>
        <div className={classes.postDetailsBanner}>
          <DialogTitle>
            Create a New {capitalizeFirstLetter(this.state.type)} Post
          </DialogTitle>
          <MyButton
            tip="Close"
            onClick={this.handleClose}
            tipClassName={classes.closeButton}
          >
            <CloseIcon />
          </MyButton>
        </div>
        <DialogContent className={classes.createPostDialog}>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            disabled={this.state.done}
          >
            <Button
              color={this.state.type === "article" ? "primary" : "secondary"}
              onClick={this.setArticle}
            >
              Article
            </Button>
            <Button
              color={this.state.type === "photo" ? "primary" : "secondary"}
              onClick={this.setPhoto}
            >
              Photo
            </Button>
            <Button
              color={this.state.type === "text" ? "primary" : "secondary"}
              onClick={this.setText}
            >
              Text
            </Button>
            <Button
              color={this.state.type === "video" ? "primary" : "secondary"}
              onClick={this.setVideo}
            >
              Video
            </Button>
          </ButtonGroup>

          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            disabled={this.state.done}
          >
            <Button
              color={this.state.type === "announce" ? "primary" : "secondary"}
              onClick={this.setAnnounce}
            >
              Announce
            </Button>
            <Button
              color={this.state.type === "feedback" ? "primary" : "secondary"}
              onClick={this.setFeedback}
            >
              Feedback
            </Button>
            <Button
              color={this.state.type === "poll" ? "primary" : "secondary"}
              onClick={this.setPoll}
            >
              Poll
            </Button>
          </ButtonGroup>

          <form onSubmit={this.handleSubmit} className={classes.createPostForm}>
            {this.state.type === "article" ? (
              <Editor
                toolbarClassName={classes.textEditorToolbar}
                wrapperClassName={classes.textEditorWrapper}
                editorClassName={classes.textEditor}
                onEditorStateChange={this.onEditorStateChange}
                disabled={this.state.done}
              />
            ) : null}

            <TextField
              id="content"
              name="content"
              type="text"
              label="Content"
              multiline
              rows="2"
              variant="outlined"
              placeholder="Post content..."
              error={errors.content ? true : false}
              helperText={errors.content}
              className={classes.textFields}
              onChange={this.handleChange}
              disabled={this.state.done}
              fullWidth
            />

            {post_types.includes(this.state.type) ? (
              <Fragment>
                <TextField
                  id="location"
                  name="location"
                  type="text"
                  label="Location"
                  rows="1"
                  placeholder="Location"
                  error={errors.location ? true : false}
                  helperText={errors.location}
                  className={classes.textFields}
                  onChange={this.handleChange}
                  disabled={this.state.done}
                  variant="outlined"
                />
                <div className={classes.latlon}>
                  <TextField
                    id="lat"
                    name="lat"
                    type="text"
                    label="Lat"
                    rows="1"
                    placeholder="Latitude"
                    error={errors.lat ? true : false}
                    helperText={errors.lat}
                    className={classes.textFields}
                    onChange={this.handleChange}
                    disabled={this.state.done}
                    variant="outlined"
                  />

                  <TextField
                    id="lon"
                    name="lon"
                    type="text"
                    label="Lon"
                    rows="1"
                    placeholder="Longitude"
                    error={errors.lon ? true : false}
                    helperText={errors.lon}
                    className={classes.textFields}
                    onChange={this.handleChange}
                    disabled={this.state.done}
                    variant="outlined"
                  />
                </div>
                <div className="tag-control">
                  <Box>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        label="Tag"
                        sx={{ minWidth: "200px" }}
                        disabled={this.state.done}
                        onChange={this.setTag}
                      >
                        <MenuItem button key="empty" value="">
                          <i>None</i>
                        </MenuItem>
                        {tags.map((tag) => (
                          <MenuItem button key={tag.name} value={tag.name}>
                            {tag.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Fragment>
            ) : null}

            {this.state.type === "photo" ? (
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.buttonSecondary}
                  onClick={this.handleImageUploadsPress}
                  error={errors.dataURLs ? true : false}
                  helperText={errors.dataURLs}
                  disabled={this.state.done}
                  disableElevation
                >
                  Upload Images
                </Button>
                <input
                  type="file"
                  id="imageInput"
                  hidden="hidden"
                  onChange={this.handleImageUploads}
                  multiple
                />

                {this.state.errors.dataURLs ? (
                  <p className={classes.error}>{this.state.errors.dataURLs}</p>
                ) : null}
                <div className={classes.uploadList}>{uploadList}</div>
              </div>
            ) : this.state.type === "video" ? (
              <Fragment>
                <div>
                  <TextField
                    id="videoURL"
                    name="videoURL"
                    type="text"
                    label="Video URL"
                    variant="outlined"
                    error={errors.videoURL ? true : false}
                    helperText={errors.videoURL}
                    className={classes.textFields}
                    onChange={this.handleChange}
                    disabled={this.state.done}
                    fullWidth
                  />
                </div>
              </Fragment>
            ) : null}

            {this.state.type === "article" || this.state.type === "video" ? (
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.buttonSecondary}
                  onClick={this.handleThumbnailUploadsPress}
                  error={errors.thumbnail ? true : false}
                  helperText={errors.thumbnail}
                  disabled={this.state.done}
                  disableElevation
                >
                  Upload Thumbnail
                </Button>
                <input
                  type="file"
                  id="thumbnailInput"
                  hidden="hidden"
                  onChange={this.handleVideoThumbnailUpload}
                  multiple
                />
                {this.state.errors.thumbnail ? (
                  <p className={classes.error}>{this.state.errors.thumbnail}</p>
                ) : null}
                {this.state.thumbnailName.length > 0 ? (
                  <p>{this.state.thumbnailName}</p>
                ) : null}
              </div>
            ) : null}

            {this.state.type === "poll" ? (
              <div>
                {pollOptionFields}
                <Button onClick={this.addPollOption}>Add</Button>
                {endsInField}
              </div>
            ) : null}

            {!this.state.done && (
              <Button
                onClick={this.handleDone}
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={loading}
              >
                Done
              </Button>
            )}
          </form>
        </DialogContent>

        {this.state.done && (
          <DialogActions>
            <Button onClick={this.handleNotDone} color="secondary">
              Nevermind I'm Not Done
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress
                  size={30}
                  className={classes.progressSpinner}
                />
              )}
            </Button>
          </DialogActions>
        )}
      </Fragment>
    );

    return (
      <Fragment>
        <MediaQuery query="(min-width: 1201px)">
          <MenuItem className={classes.navMenuItem} onClick={this.handleOpen}>
            {this.state.open ? (
              <Icon
                className={classes.navMenuItemIcon}
                fontSize="medium"
                color="secondary"
                component={icon_set["createFilled"]}
              />
            ) : (
              <Icon
                className={classes.navMenuItemIcon}
                fontSize="medium"
                color="info"
                component={icon_set["createOutlined"]}
              />
            )}
            <Typography
              className={classes.navMenuItem}
              color={this.state.open ? "secondary" : "info"}
              variant="body1"
              style={{ fontWeight: 500 }}
            >
              Create Post
            </Typography>
          </MenuItem>
        </MediaQuery>

        <MediaQuery query="(max-width: 1200px)">
          <Fab
            color="secondary"
            aria-label="add"
            className={classes.fab}
            onClick={this.handleOpen}
          >
            <AddIcon />
          </Fab>
        </MediaQuery>

        <MediaQuery query="(min-width: 1200px)">
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth={this.state.maxWidth}
          >
            {dialogContent}
          </Dialog>
        </MediaQuery>

        <MediaQuery query="(max-width: 1200px)">
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullScreen
            maxWidth={this.state.maxWidth}
          >
            {dialogContent}
          </Dialog>
        </MediaQuery>
      </Fragment>
    );
  }
}

CreatePost.propTypes = {
  createPost: PropTypes.func.isRequired,
  clearCreatePostSuccess: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI,
});

export default connect(mapStateToProps, { createPost, clearErrors,clearCreatePostSuccess })(
  withStyles(styles)(CreatePost)
);
