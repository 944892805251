import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
// MUI

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
    ...theme.spreadThis,
    notsubedContainer: {
      display: 'flex',
      flexDirection:'column',
      alignItems:"center",
      background:"#f4f6f6",
      borderRadius:15,
      padding: "20px 30px"
    },
    hightlight:{
        fontWeight:"bold",
        fontSize:16,
        color:"#3a4387"
    },
     disclaimer:{
        fontWeight:"bold",
        fontSize:14,
        color:"#938d94",
        maxWidth:500,
    }
})

export class NotSubscribed extends Component {
  render() {
      const {classes} = this.props;
    return (
      <div className={classes.notsubedContainer}>
          {/* <h1>Welcome</h1> */}
          <h1>Thank you for joining!</h1>
          <p>This page contains exclusive content and a premium account is reqiured. Please visit the <span className={classes.hightlight}>Subscription Tab</span> in the
          <span className={classes.hightlight}> Manage Page</span> if you would like to purchase a premium subscription.</p>
              <Link to="/manage">
                  <Button color="secondary" variant="contained">
                      Go To Manage Page
                  </Button>
                  </Link>
              
            <p className={classes.disclaimer}>If you have just purchased a subscription, please wait up to 5 minutes and refresh the page to see changes. Do not try again as it will create two purchases on your card.
            If the problem persists, please sign out and login again.
            </p>

            
            
      </div>
    )
  }
}

NotSubscribed.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(styles)(NotSubscribed);