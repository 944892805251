import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import AppIcon from '../images/Red & Cream Classic Van Summer Holiday Travel Logo With Surfboard And Palm Tree.png';

// REDUX Stuff
import { connect } from 'react-redux';
import { resetPassword } from '../redux/actions/userActions';

// MUI Stuff
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


const styles = (theme) => ({
  ...theme.spreadThis,
  welcomeButtons:{
    borderRadius: "12px",
    width:"200px",
    fontWeight:"bold",
    margin:"20px"
  },
})

export class forgot_password extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.UI.errors) {
    this.setState({ errors: nextProps.UI.errors });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email
        };
    this.props.resetPassword(userData.email);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { classes, UI: { loading, message } } = this.props;
    const { errors } = this.state;

    return(
      <div className="container">
    <Grid container className={classes.form}>
    <Snackbar open={message !== null} autoHideDuration={2000}  anchorOrigin={{ vertical:'top' , horizontal:'center' }} >
  <Alert severity="success" sx={{ width: '100%' }}>
    {message}
  </Alert>
</Snackbar>
      <Grid item sm/>
      <Grid item sm> 
      <img src={AppIcon} alt="Icon" className={classes.icon}/>
      <h1 className={classes.pageTitle}>
        Forgot Password
      </h1>
      <form noValidate onSubmit={this.handleSubmit}>
      <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              className={classes.textField}
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              variant="outlined" 
              fullWidth
            />

            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}

            
            {
                message === null ? 
                (
                    <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    className={classes.welcomeButtons}
                    disableElevation
                    disabled={loading}
                  >
                    Reset Password
                    {loading && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>  
                )
                : (
                    <Button className={classes.welcomeButtons} variant="contained" color="secondary" size="large" component={Link} to="/login" disableElevation>Login</Button>

                )
            }
      </form>
      </Grid>
      <Grid item sm/>
    </Grid>
    </div>
    )
  }
}

forgot_password.propTypes = {
  classes: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  resetPassword
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(forgot_password));
