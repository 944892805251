import PropTypes from 'prop-types';
import { Component } from 'react';
import MediaQuery from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import { desktopMediaQuery, mobileMediaQuery } from '../util/Global';

// Redux
import { connect } from 'react-redux';
import { getPost, getPosts, loadMoreFilteredPosts, loadMorePosts } from '../redux/actions/dataActions';

// My Components
import CreatePost from '../components/CreatePost';
import Error from '../components/Error';
import HomeFilterMenu from '../components/HomeFilterMenu';
import MobileNavBar from '../components/MobileNavBar';
import NavMenu from '../components/NavMenu';
import NotSubscribed from '../components/NotSubscribed';
import Post from '../components/Post';
import PostDialog from '../components/PostDialog';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import withStyles from "@mui/styles/withStyles";

// Other Components
import InfiniteScroll from 'react-infinite-scroller';


const styles = (theme) => ({
  ...theme.spreadThis
});

class home extends Component {

  state = {
    postIdParam: null,

  }

  componentDidMount() {

    if (this.props.user.authenticated === true) {
      this.props.getPosts();
      const postId = this.props.match.params.postId;
      if (postId) {
        this.setState({ postIdParam: postId });
      } else {
        this.setState({ postIdParam: null });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.user.authenticated !== prevProps.user.authenticated)) {
      if (this.props.user.authenticated === true) {
        this.props.getPosts();
        const postId = this.props.match.params.postId;
        if (postId) {
          this.setState({ postIdParam: postId });
        }
      }
    }
  }

  handler() {
  }


  render() {
    const { posts, lastKey, loading, post, filterOptions } = this.props.data;
    const { classes, user: { admin, authenticated }, UI: {errors} } = this.props;
    const { postIdParam } = this.state;

    // const data_loading_errors = errors.hasOwnProperty('data');

    const hasFilter = () => {
      if (filterOptions.FILTER_type !== "" ||
        filterOptions.FILTER_dateStart !== "" ||
        filterOptions.FILTER_dateEnd !== "" ||
        filterOptions.FILTER_tag !== "" ||
        filterOptions.FILTER_tagId !== "") {
        return true
      }
      else return false

    }

    

    const handleLoadMorePosts = () => {
      if (lastKey !== null && lastKey !== undefined && !loading) {
        if (!hasFilter) { this.props.loadMorePosts(lastKey); }
        else { this.props.loadMoreFilteredPosts(lastKey, filterOptions); }
      }
    };

    let recentPostsMarkup = !errors.unsubscribed ? ((!loading && authenticated) ? (

      <div>
        
      {errors.data ? (
      <Error/>
      ) : (
        <CSSTransition
          classNames="fade"
          in={!loading}
          appear={true}
          timeout={800}
          >
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => { handleLoadMorePosts() }}
        hasMore={lastKey !== "DONE"}
        loader={<div className="center" key={0}><CircularProgress /></div>}
        useWindow={true}
        // style={{ padding: "5px" }}
      >
        {(posts.length === 0) ? (
          <div className="center">
              <h1>😎</h1>
              <p>Nothin' to see here folks.</p>
          </div>
          
            ):(
        posts.map(thisPost => <Post key={thisPost.postId} post={thisPost} />)
            )}
      </InfiniteScroll>
      </CSSTransition>
      )}
      </div>
    ) : (
      <div className="center" style={{paddingTop:200}} key={0}><CircularProgress size={60} thickness={6.5} color="secondary"/></div>  
    )
    ) : (
      <NotSubscribed/>
    )

    return (
      <div>
        {authenticated ? (
          <div className={classes.mainPageContainer}>
            <Grid container justifyContent="center" spacing={0} className={classes.mainFeedGridContainer}>
              <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
                <div className={classes.navMenuContainer}>
                  <NavMenu />
                </div>
              </Grid>

              <MediaQuery query={mobileMediaQuery}>
              <MobileNavBar />
                <Grid item sm={8} md={8} xs={12} className={classes.mainFeedItemContainer}>
                <CSSTransition
          classNames="quickFade"
          in={loading}
          appear={true}
          timeout={600}
          >
            
          {recentPostsMarkup}
          
          </CSSTransition>
                  {(postIdParam !== null && !loading) ? (<PostDialog key={postIdParam} postId={postIdParam} handler={this.handler} openDialog={true} />) : null}
                  {admin && <CreatePost />}
                  
                </Grid>
              </MediaQuery>

              <MediaQuery query={desktopMediaQuery}>
                <Grid item sm={6} md={6} xs={12} className={classes.mainFeedItemContainer}>
                  {recentPostsMarkup}
                  {(postIdParam !== null && !loading) ? (<PostDialog key={postIdParam} postId={postIdParam} handler={this.handler} openDialog={true} />) : null}
                </Grid>
              </MediaQuery>


              <Grid item sm={3} xs={12} display={{ xs: "none", lg: "block" }}>
                <div className={classes.filterMenuContainer}>
                  <HomeFilterMenu />
                </div>
              </Grid>
            </Grid>

          </div>
        ) : (null)}
      </div>
    );
  }
}

home.propTypes = {
  classes: PropTypes.object.isRequired,
  getPosts: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
  loadMorePosts: PropTypes.func.isRequired,
  loadMoreFilteredPosts: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
})

export default connect(mapStateToProps, { getPosts, loadMorePosts, loadMoreFilteredPosts, getPost })(withStyles(styles)(home));
